import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, Datepicker} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/patientVitals.css';
import dayjs from "dayjs";
import PatientVitalsMetric from "../dialogs/PatientVitalsMetric";
import PatientVitalsRange from "../dialogs/PatientVitalsRange";
import {Line, Bar} from "react-chartjs-2";
import Empty from "../utils/Empty"
import Loading from "../utils/Loading";
import {faWatchFitness} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NetworkContext} from "../utils/NetworkContext";

function PatientVitals({locale, Dialog, dialog, patient, date, setDate, configuration}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [range, setRange] = useState('day');
    const [metric, setMetric] = useState(() => {
        if (locale.patientVitalsMetric.a.length > 0) {
            const firstObject = locale.patientVitalsMetric.a[0];
            const firstPropertyName = Object.keys(firstObject)[0];
            return firstPropertyName;
        }
        return locale.patientVitals.k;
    });
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [vitalsArray, setVitalsArray] = useState([]);
    const [vitalsObject, setVitalsObject] = useState({});
    const [vitalsStats, setVitalsStats] = useState({});
    const [loading, setLoading] = useState(false);

    const Vital = () => {
        if (metric === 'pulse' || metric === 'pulseShared') {
            return locale.patientVitals.c;
        } else if (metric === 'height') {
            return locale.patientVitals.g;
        } else if (metric === 'weight') {
            return locale.patientVitals.h;
        } else if (metric === 'temperature') {
            return locale.patientVitals.b;
        } else if (metric === 'rr') {
            return locale.patientVitals.d;
        } else if (metric === 'bp' || metric === 'bpShared') {
            return locale.patientVitals.e;
        } else if (metric === 'o2Saturation') {
            return locale.patientVitals.f;
        } else if (metric === 'bmi') {
            return locale.patientVitals.i;
        } else if (metric === 'glucose') {
            return locale.patientVitals.j;
        } else if (metric === 'activityShared') {
            return locale.patientVitals.ae;
        } else if (metric === 'sleepShared') {
            return locale.patientVitals.ad;
        }
    }

    const Range = () => {
        if (range === 'week') {
            return locale.patientVitals.l;
        } else if (range === 'month') {
            return locale.patientVitals.m;
        } else if (range === 'day') {
            return locale.patientVitals.n;
        }
    }

    const Format = () => {
        if (range === 'day') {
            return "YYYY-M-D";
        } else if (range === 'week') {
            return "YYYY-M-D";
        } else if (range === 'month') {
            return "YYYY-M";
        }
    }

    const Wheels = () => {
        if (range === 'day') {
            return "|YYYY | MMM | D|"
        } else if (range === 'week') {
            return "|YYYY | MMM | D|"
        } else if (range === 'month') {
            return "|YYYY | MMM|"
        }
    }

    const Vitals = () => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'vitals-metric',
            action: 'get',
            data: {
                patient: patient.id,
                metric: metric,
                range: range,
                date: date
            }
        };
        wsCall(message);
    }

    const Date = data => {
        const itemDate = dayjs(data).format("YYYY-MM-DD")
        setDate(itemDate);
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'vitals-metric',
            action: 'get',
            data: {
                patient: patient.id,
                metric: metric,
                range: range,
                date: itemDate
            }
        };
        wsCall(message);
    }

    const ItemFormat = data => {
        if ((metric === 'bp' || metric === 'bpShared') && data.includes('_')) {
            return "mbsc-bold cs-patient-vitals-grid-item-diastolic";
        } else if ((metric === 'bp' || metric === 'bpShared') && !data.includes('_')) {
            return "mbsc-bold cs-patient-vitals-grid-item-systolic";
        } else {
            return "mbsc-bold mbsc-txt-muted";
        }
    }

    const Item = (data) => {

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-bold mbsc-txt-muted">{data.item.date}</div>
                </div>
                {data.item.time &&
                    <div className="mbsc-col">
                        <div className="mbsc-bold mbsc-txt-muted">{data.item.time}</div>
                    </div>
                }
                {(data.item.value || data.item.value === 0 || data.item.value === null) &&
                    <div className="mbsc-col">
                        <div className="mbsc-bold mbsc-txt-muted" >{data.item.value}</div>
                    </div>
                }
                {(data.item.min || data.item.min === 0 || data.item.min === null) &&
                    <div className="mbsc-col">
                        <div className={ItemFormat(data.item.id)} >{data.item.min}</div>
                    </div>
                }
                {(data.item.max || data.item.max === 0 || data.item.max === null) &&
                    <div className="mbsc-col">
                        <div className={ItemFormat(data.item.id)} >{data.item.max}</div>
                    </div>
                }
                {(data.item.average || data.item.average === 0 || data.item.average === null) &&
                    <div className="mbsc-col">
                        <div className={ItemFormat(data.item.id)} >{data.item.average}</div>
                    </div>
                }
                {(data.item.readings || data.item.readings === 0 || data.item.readings === null) &&
                    <div className="mbsc-col">
                        <div className={ItemFormat(data.item.id)} >{data.item.readings}</div>
                    </div>
                }
                {(data.item.one || data.item.one === 0 || data.item.one === null) &&
                    <div className="mbsc-col">
                        <div className="mbsc-bold mbsc-txt-muted" >{data.item.one}</div>
                    </div>
                }
                {(data.item.two || data.item.two === 0 || data.item.two === null) &&
                    <div className="mbsc-col">
                        <div className="mbsc-bold mbsc-txt-muted" >{data.item.two}</div>
                    </div>
                }
                {(data.item.three || data.item.three === 0 || data.item.three === null) &&
                    <div className="mbsc-col">
                        <div className="mbsc-bold mbsc-txt-muted" >{data.item.three}</div>
                    </div>
                }
                {(data.item.four || data.item.four === 0 || data.item.four === null) &&
                    <div className="mbsc-col">
                        <div className="mbsc-bold mbsc-txt-muted" >{data.item.four}</div>
                    </div>
                }
                {(data.item.five || data.item.five === 0 || data.item.five === null) &&
                    <div className="mbsc-col">
                        <div className="mbsc-bold mbsc-txt-muted" >{data.item.five}</div>
                    </div>
                }
            </div>
        </li>;
    }

    const GetVitalsMetric = useCallback(data => {
        if (data.chartData && range === 'week') {
            data.chartData.labels = locale.patientVitals.o;
        } else if (data.chartData && range === 'month') {
            data.chartData.labels = configuration.chart.data.month;
        }
        if (data.array) {
            setVitalsArray(data.array);
        } else {
            setVitalsArray([]);
        }
        if (data.object) {
            setVitalsObject(data.object);
        } else {
            setVitalsObject({});
        }
        if (data.chartData && (metric === 'pulse' || metric === 'pulseShared') && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.patientVitals.r;
        } else if (data.chartData && (metric === 'pulse' || metric === 'pulseShared') && data.chartData.datasets.length === 3) {
            data.chartData.datasets[0].label = locale.patientVitals.as + ' ' + locale.patientVitals.r;
            data.chartData.datasets[1].label = locale.patientVitals.au + ' ' + locale.patientVitals.r;
            data.chartData.datasets[2].label = locale.patientVitals.at + ' ' + locale.patientVitals.r;
        } else if (data.chartData && (metric === 'bp' || metric === 'bpShared') && data.chartData.datasets.length === 2) {
            data.chartData.datasets[0].label = locale.patientVitals.p;
            data.chartData.datasets[1].label = locale.patientVitals.q;
        } else if (data.chartData && (metric === 'bp' || metric === 'bpShared') && data.chartData.datasets.length === 6) {
            data.chartData.datasets[0].label = locale.patientVitals.av + ' ' + locale.patientVitals.p;
            data.chartData.datasets[1].label = locale.patientVitals.ax + ' ' + locale.patientVitals.p;
            data.chartData.datasets[2].label = locale.patientVitals.aw + ' ' + locale.patientVitals.p;
            data.chartData.datasets[3].label = locale.patientVitals.av + ' ' + locale.patientVitals.q;
            data.chartData.datasets[4].label = locale.patientVitals.ax + ' ' + locale.patientVitals.q;
            data.chartData.datasets[5].label = locale.patientVitals.aw + ' ' + locale.patientVitals.q;
        } else if (data.chartData && metric === 'temperature' && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.patientVitals.s;
        } else if (data.chartData && metric === 'temperature' && data.chartData.datasets.length === 3) {
            data.chartData.datasets[0].label = locale.patientVitals.as + ' ' + locale.patientVitals.s;
            data.chartData.datasets[1].label = locale.patientVitals.au + ' ' + locale.patientVitals.s;
            data.chartData.datasets[2].label = locale.patientVitals.at + ' ' + locale.patientVitals.s;
        } else if (data.chartData && metric === 'o2Saturation' && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.patientVitals.u;
        } else if (data.chartData && metric === 'o2Saturation' && data.chartData.datasets.length === 3) {
            data.chartData.datasets[0].label = locale.patientVitals.as + ' ' + locale.patientVitals.u;
            data.chartData.datasets[1].label = locale.patientVitals.au + ' ' + locale.patientVitals.u;
            data.chartData.datasets[2].label = locale.patientVitals.at + ' ' + locale.patientVitals.u;
        } else if (data.chartData && metric === 'rr' && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.patientVitals.v;
        } else if (data.chartData && metric === 'rr' && data.chartData.datasets.length === 3) {
            data.chartData.datasets[0].label = locale.patientVitals.as + ' ' + locale.patientVitals.v;
            data.chartData.datasets[1].label = locale.patientVitals.au + ' ' + locale.patientVitals.v;
            data.chartData.datasets[2].label = locale.patientVitals.at + ' ' + locale.patientVitals.v;
        } else if (data.chartData && metric === 'glucose' && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.patientVitals.w;
        } else if (data.chartData && metric === 'glucose' && data.chartData.datasets.length === 3) {
            data.chartData.datasets[0].label = locale.patientVitals.as + ' ' + locale.patientVitals.w;
            data.chartData.datasets[1].label = locale.patientVitals.au + ' ' + locale.patientVitals.w;
            data.chartData.datasets[2].label = locale.patientVitals.at + ' ' + locale.patientVitals.w;
        } else if (data.chartData && metric === 'height' && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.patientVitals.x;
        } else if (data.chartData && metric === 'height' && data.chartData.datasets.length === 3) {
            data.chartData.datasets[0].label = locale.patientVitals.as + ' ' + locale.patientVitals.x;
            data.chartData.datasets[1].label = locale.patientVitals.au + ' ' + locale.patientVitals.x;
            data.chartData.datasets[2].label = locale.patientVitals.at + ' ' + locale.patientVitals.x;
        } else if (data.chartData && metric === 'weight' && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.patientVitals.y;
        } else if (data.chartData && metric === 'weight' && data.chartData.datasets.length === 3) {
            data.chartData.datasets[0].label = locale.patientVitals.as + ' ' + locale.patientVitals.y;
            data.chartData.datasets[1].label = locale.patientVitals.au + ' ' + locale.patientVitals.y;
            data.chartData.datasets[2].label = locale.patientVitals.at + ' ' + locale.patientVitals.y;
        } else if (data.chartData && metric === 'bmi' && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.patientVitals.z;
        } else if (data.chartData && metric === 'bmi' && data.chartData.datasets.length === 3) {
            data.chartData.datasets[0].label = locale.patientVitals.as + ' ' + locale.patientVitals.z;
            data.chartData.datasets[1].label = locale.patientVitals.au + ' ' + locale.patientVitals.z;
            data.chartData.datasets[2].label = locale.patientVitals.at + ' ' + locale.patientVitals.z;
        } else if (data.chartData && metric === 'activityShared' && data.chartData.datasets.length === 1) {
            data.chartData.datasets[0].label = locale.patientVitals.t + (range === 'day' ? (' (' + data.total.toLocaleString() + ')') : '');
        } else if (data.chartData && metric === 'sleepShared' && data.chartData.datasets.length === 5) {
            data.chartData.datasets[0].label = locale.patientVitals.af;
            data.chartData.datasets[1].label = locale.patientVitals.ag;
            data.chartData.datasets[2].label = locale.patientVitals.ah;
            data.chartData.datasets[3].label = locale.patientVitals.ai;
            data.chartData.datasets[4].label = locale.patientVitals.aj;
        }
        if (data.chartData) {
            setChartData(data.chartData);
        }
        const item = configuration.chart.options;
        if (data.text) {
            item.plugins.title.text = data.text;
            item.plugins.title.display = true;
        } else {
            item.plugins.title.display = false;
        }
        if (metric === 'sleepShared' && range === 'day') {
            item.scales.x.stacked = true;
            item.scales.y.stacked = true;
        } else {
            item.scales.x.stacked = false;
            item.scales.y.stacked = false;
        }
        setChartOptions(item);
        if (data.stats) {
            setVitalsStats(data.stats);
        } else {
            setVitalsStats({});
        }
        setLoading(false);
    }, [configuration.chart.options, locale.patientVitals.ar, locale.patientVitals.ai, locale.patientVitals.aj, locale.patientVitals.af, locale.patientVitals.ag, locale.patientVitals.ah, locale.patientVitals.z, locale.patientVitals.y, locale.patientVitals.x, locale.patientVitals.w, locale.patientVitals.v, locale.patientVitals.u, locale.patientVitals.t, locale.patientVitals.r, locale.patientVitals.s, locale.patientVitals.o, metric, locale.patientVitals.p, locale.patientVitals.q, range, configuration.chart.data.month]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'vitals-metric') {
            GetVitalsMetric(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetVitalsMetric, setWsResponse]);

    useEffect(() => {
        Vitals();
    }, []);

    return (
        <div className="cs-patient-vitals-page">
            <div className="mbsc-row cs-patient-vitals-section cs-patient-vitals-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-vitals-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientVitals.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row" >
                        <Button disabled={locale.patientVitalsMetric.a.length === 0} color="primary" variant="flat" className="cs-patient-vitals-picker cs-patient-vitals-button mbsc-bold mbsc-txt-muted" >
                            <Datepicker
                                controls={['date']}
                                touchUi={false}
                                value={date}
                                inputComponent="input"
                                dateFormat={Format()}
                                dateWheels={Wheels()}
                                max={dayjs().format("YYYY-MM-DD")}
                                buttons={['set', 'cancel']}
                                onChange={ev => Date(ev.value)}
                                setText={locale.patientVitals.aa}
                                cancelText={locale.patientVitals.ab}
                            />
                        </Button>
                        <Button disabled={locale.patientVitalsMetric.a.length === 0} id="patientVitalsRange" color="primary" variant="flat" className="cs-patient-vitals-button mbsc-bold mbsc-txt-muted" onClick={() => Dialog({view: 'patientVitalsRange'})} >
                            {Range()}
                        </Button>
                        <Button disabled={locale.patientVitalsMetric.a.length === 0} id="patientVitalsMetric" color="primary" variant="flat" className="cs-patient-vitals-button mbsc-bold mbsc-txt-muted" onClick={() => Dialog({view: 'patientVitalsMetric'})} >
                            {Vital()}{(metric === 'pulseShared' || metric === 'activityShared' || metric === 'bpShared' || metric === 'sleepShared') && <FontAwesomeIcon className="cs-patient-vitals-icon" icon={faWatchFitness} />}
                        </Button>
                    </div>
                </div>
            </div>
            {!loading && !Empty(chartOptions) && !Empty(chartData) && metric !== 'sleepShared' &&
                <div className="mbsc-row cs-patient-vitals-chart">
                    <Line options={chartOptions} data={chartData} />
                </div>
            }
            {!loading && !Empty(chartOptions) && !Empty(chartData) && metric === 'sleepShared' &&
                <div className="mbsc-row cs-patient-vitals-chart">
                    <Bar options={chartOptions} data={chartData} />
                </div>
            }
            {loading &&
                <div className="cs-patient-vitals-loading" >
                    <Loading />
                </div>
            }
            {!loading && !Empty(vitalsObject) && metric === 'sleepShared' && (vitalsObject.total.hours !== 0 || vitalsObject.total.minutes !== 0) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-txt-muted">
                    <h4>
                        {locale.patientVitals.ac}: {vitalsObject.total.hours !== 0 ? (vitalsObject.total.hours + ' ' + (vitalsObject.total.hours === 1 ? locale.patientVitals.ap : locale.patientVitals.an)) : null} {vitalsObject.total.minutes !== 0 ? (vitalsObject.total.minutes + ' ' + (vitalsObject.total.minutes === 1 ? locale.patientVitals.aq : locale.patientVitals.ao)) : null}
                    </h4>
                </div>
            }
            {!loading && !Empty(vitalsObject) && metric === 'sleepShared' && (vitalsObject.one.hours !== 0 || vitalsObject.one.minutes !== 0) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-txt-muted">
                    <h4>
                        {locale.patientVitals.af}: {vitalsObject.one.hours !== 0 ? (vitalsObject.one.hours + ' ' + (vitalsObject.one.hours === 1 ? locale.patientVitals.ap : locale.patientVitals.an)) : null} {vitalsObject.one.minutes !== 0 ? (vitalsObject.one.minutes + ' ' + (vitalsObject.one.minutes === 1 ? locale.patientVitals.aq : locale.patientVitals.ao)) : null}
                    </h4>
                </div>
            }
            {!loading && !Empty(vitalsObject) && metric === 'sleepShared' && (vitalsObject.two.hours !== 0 || vitalsObject.two.minutes !== 0) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-txt-muted">
                    <h4>
                        {locale.patientVitals.ag}: {vitalsObject.two.hours !== 0 ? (vitalsObject.two.hours + ' ' + (vitalsObject.two.hours === 1 ? locale.patientVitals.ap : locale.patientVitals.an)) : null} {vitalsObject.two.minutes !== 0 ? (vitalsObject.two.minutes + ' ' + (vitalsObject.two.minutes === 1 ? locale.patientVitals.aq : locale.patientVitals.ao)) : null}
                    </h4>
                </div>
            }
            {!loading && !Empty(vitalsObject) && metric === 'sleepShared' && (vitalsObject.three.hours !== 0 || vitalsObject.three.minutes !== 0) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-txt-muted">
                    <h4>
                        {locale.patientVitals.ah}: {vitalsObject.three.hours !== 0 ? (vitalsObject.three.hours + ' ' + (vitalsObject.three.hours === 1 ? locale.patientVitals.ap : locale.patientVitals.an)) : null} {vitalsObject.three.minutes !== 0 ? (vitalsObject.three.minutes + ' ' + (vitalsObject.three.minutes === 1 ? locale.patientVitals.aq : locale.patientVitals.ao)) : null}
                    </h4>
                </div>
            }
            {!loading && !Empty(vitalsObject) && metric === 'sleepShared' && (vitalsObject.four.hours !== 0 || vitalsObject.four.minutes !== 0) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-txt-muted">
                    <h4>
                        {locale.patientVitals.ai}: {vitalsObject.four.hours !== 0 ? (vitalsObject.four.hours + ' ' + (vitalsObject.four.hours === 1 ? locale.patientVitals.ap : locale.patientVitals.an)) : null} {vitalsObject.four.minutes !== 0 ? (vitalsObject.four.minutes + ' ' + (vitalsObject.four.minutes === 1 ? locale.patientVitals.aq : locale.patientVitals.ao)) : null}
                    </h4>
                </div>
            }
            {!loading && !Empty(vitalsObject) && metric === 'sleepShared' && (vitalsObject.five.hours !== 0 || vitalsObject.five.minutes !== 0) &&
                <div className="mbsc-row mbsc-justify-content-center mbsc-txt-muted">
                    <h4>
                        {locale.patientVitals.aj}: {vitalsObject.five.hours !== 0 ? (vitalsObject.five.hours + ' ' + (vitalsObject.five.hours === 1 ? locale.patientVitals.ap : locale.patientVitals.an)) : null} {vitalsObject.five.minutes !== 0 ? (vitalsObject.five.minutes + ' ' + (vitalsObject.five.minutes === 1 ? locale.patientVitals.aq : locale.patientVitals.ao)) : null}
                    </h4>
                </div>
            }
            {!loading && !Empty(vitalsStats) &&
                <>
                    <div className="mbsc-row cs-patient-vitals-grid cs-patient-vitals-grid-header">
                        <div className="mbsc-col cs-patient-vitals-grid-item">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.as}</div>
                        </div>
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.at}</div>
                        </div>
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.au}</div>
                        </div>
                    </div>
                    <div className="mbsc-row cs-patient-vitals-grid">
                        <div className="mbsc-col cs-patient-vitals-grid-item">
                            <div className={(metric === 'bp' || metric === 'bpShared') ? "mbsc-bold cs-patient-vitals-grid-item-systolic" : "mbsc-bold cs-patient-vitals-grid-item-data"} >{(metric === 'bp' || metric === 'bpShared') ? vitalsStats.systolic.min : vitalsStats.min}</div>
                        </div>
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className={(metric === 'bp' || metric === 'bpShared') ? "mbsc-bold cs-patient-vitals-grid-item-systolic" : "mbsc-bold cs-patient-vitals-grid-item-data"} >{(metric === 'bp' || metric === 'bpShared') ? vitalsStats.systolic.max : vitalsStats.max}</div>
                        </div>
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className={(metric === 'bp' || metric === 'bpShared') ? "mbsc-bold cs-patient-vitals-grid-item-systolic" : "mbsc-bold cs-patient-vitals-grid-item-data"} >{(metric === 'bp' || metric === 'bpShared') ? vitalsStats.systolic.average : vitalsStats.average}</div>
                        </div>
                    </div>
                    {(metric === 'bp' || metric === 'bpShared') &&
                        <div className="mbsc-row cs-patient-vitals-grid">
                            <div className="mbsc-col cs-patient-vitals-grid-item">
                                <div className="mbsc-bold cs-patient-vitals-grid-item-diastolic" >{(metric === 'bp' || metric === 'bpShared') ? vitalsStats.diastolic.min : vitalsStats.min}</div>
                            </div>
                            <div className="mbsc-col cs-patient-vitals-grid-item-end">
                                <div className="mbsc-bold cs-patient-vitals-grid-item-diastolic" >{(metric === 'bp' || metric === 'bpShared') ? vitalsStats.diastolic.max : vitalsStats.max}</div>
                            </div>
                            <div className="mbsc-col cs-patient-vitals-grid-item-end">
                                <div className="mbsc-bold cs-patient-vitals-grid-item-diastolic" >{(metric === 'bp' || metric === 'bpShared') ? vitalsStats.diastolic.average : vitalsStats.average}</div>
                            </div>
                        </div>
                    }
                </>
            }
            {!loading && vitalsArray.length !== 0 &&
                <div className="mbsc-row cs-patient-vitals-grid cs-patient-vitals-grid-header">
                    <div className="mbsc-col cs-patient-vitals-grid-item">
                        <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.ak}</div>
                    </div>
                    {vitalsArray[0].time &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.al}</div>
                        </div>
                    }
                    {(vitalsArray[0].value || vitalsArray[0].value === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.am}</div>
                        </div>
                    }
                    {(vitalsArray[0].min || vitalsArray[0].min === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.as}</div>
                        </div>
                    }
                    {(vitalsArray[0].max || vitalsArray[0].max === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.at}</div>
                        </div>
                    }
                    {(vitalsArray[0].average || vitalsArray[0].average === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.au}</div>
                        </div>
                    }
                    {(vitalsArray[0].readings || vitalsArray[0].readings === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.ar}</div>
                        </div>
                    }
                    {(vitalsArray[0].one || vitalsArray[0].one === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.af}</div>
                        </div>
                    }
                    {(vitalsArray[0].two || vitalsArray[0].two === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.ag}</div>
                        </div>
                    }
                    {(vitalsArray[0].three || vitalsArray[0].three === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.ah}</div>
                        </div>
                    }
                    {(vitalsArray[0].four || vitalsArray[0].four === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.ai}</div>
                        </div>
                    }
                    {(vitalsArray[0].five || vitalsArray[0].five === null) &&
                        <div className="mbsc-col cs-patient-vitals-grid-item-end">
                            <div className="mbsc-bold mbsc-txt-muted">{locale.patientVitals.aj}</div>
                        </div>
                    }
                </div>
            }
            {!loading && vitalsArray.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={Item}
                    data={vitalsArray}
                />
            }
            <div className="mbsc-row cs-patient-vitals-footer" />
            {dialog.view === 'patientVitalsMetric' &&
                <PatientVitalsMetric
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setMetric={setMetric}
                    range={range}
                    patient={patient}
                    date={date}
                    setLoading={setLoading}
                />
            }
            {dialog.view === 'patientVitalsRange' &&
                <PatientVitalsRange
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setRange={setRange}
                    metric={metric}
                    patient={patient.id}
                    date={date}
                    setLoading={setLoading}
                />
            }
        </div>
    );
}

export default PatientVitals;
