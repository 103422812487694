import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import '../styles/patientAllergies.css';
import {faPanFood, faTrees, faPills, faTrashCan} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import PatientAllergy from "../dialogs/PatientAllergy";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientAllergies({locale, setPatient, patient, dialog, Dialog}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [allergies, setAllergies] = useState(patient.allergies);
    const [allergy, setAllergy] = useState({});

    const Delete = data => {
        confirm({
            title: locale.patientAllergies.g,
            message: locale.patientAllergies.h,
            okText: locale.patientAllergies.i,
            cancelText: locale.patientAllergies.j,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'patient-allergy',
                        action: 'delete',
                        data: {
                            patient: patient.id,
                            allergies: allergies,
                            id: data
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Create = () => {
        setAllergy({});
        Dialog({view: 'patientAllergy'});
    }

    const Open = data => {
        setAllergy(data);
        Dialog({view: 'patientAllergy'});
    }

    const Item = data => {
        const itemDate = dayjs(parseInt(data.item.id)).format("MMMM D, YYYY");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-allergies-list" onClick={(ev) => Open(data.item)}>
                        {data.item.type === 'medical' &&
                            <FontAwesomeIcon className="cs-patient-allergies-list-icon-medical" icon={faPills} />
                        }
                        {data.item.type === 'environmental' &&
                            <FontAwesomeIcon className="cs-patient-allergies-list-icon-environment" icon={faTrees} />
                        }
                        {data.item.type === 'dietary' &&
                            <FontAwesomeIcon className="cs-patient-allergies-list-icon-food" icon={faPanFood} />
                        }
                        <div className="cs-patient-allergies-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.text}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-allergies-item-txt">
                                {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-allergies-list-button">
                    <Button color="primary" variant="flat" onClick={() => Delete(data.item.id)} >
                        <FontAwesomeIcon className="cs-patient-allergies-icon-right" icon={faTrashCan} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const DeletePatientAllergy = useCallback(data => {
        if (data.allergies) {
            const item = {...patient};
            item.allergies = data.allergies;
            setPatient(item);
            setAllergies(data.allergies);
        }
    }, [patient]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'patient-allergy') {
            DeletePatientAllergy(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeletePatientAllergy, setWsResponse]);

    return (
        <div className="cs-patient-allergies-page">
            <div className="mbsc-row cs-patient-allergies-section cs-patient-allergies-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-allergies-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientAllergies.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <Button color="primary" variant="flat" className="cs-patient-allergies-button mbsc-bold mbsc-txt-muted" onClick={() => Create()} >
                        {locale.patientAllergies.b}
                    </Button>
                </div>
            </div>
            {allergies.length !== 0 ?
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={Item}
                    data={allergies}
                />
                :
                <div className="mbsc-row cs-patient-allergies-section cs-patient-allergies-body" />
            }
            <div className={allergies.length === 0 ? "mbsc-row cs-patient-allergies-footer-empty" : "mbsc-row cs-patient-allergies-footer"} />
            {dialog.view === 'patientAllergy' &&
                <PatientAllergy
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setPatient={setPatient}
                    setAllergies={setAllergies}
                    allergies={allergies}
                    patient={patient}
                    allergy={allergy}
                />
            }
        </div>
    );
}

export default PatientAllergies;
