import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Select, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/queueVisitTransfer.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function QueueVisitTransfer({locale, dialog, Dialog, setRefresh, visit, configuration}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [location, setLocation] = useState(() => {
        const array = configuration.locations.filter(item => item.value !== visit.location);
        return array[0].value;
    });
    const [locations, setLocations] = useState(() => {
        return configuration.locations.filter(item => item.value !== visit.location);
    });

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Transfer = () => {
        setRefresh(true);

        const message = {
            type: 'wss',
            path: 'visit-status',
            action: 'update',
            data: {
                user: visit.user,
                status: 'transfer',
                log: visit.log,
                location: location
            }
        };
        wsCall(message);
    }

    const UpdateVisitStatus = useCallback(data => {
        if (data.toast) {
            toast({message: locale.queueVisitTransfer.e, color: 'info', duration: 1000, display: 'bottom'});
        }
        CloseDialog();
        setRefresh(false);
    }, [locale.queueVisitTransfer.e, CloseDialog, dialog.view]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'visit-status') {
            UpdateVisitStatus(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateVisitStatus, setWsResponse]);

    return (
        <Popup className="cs-queue-visit-transfer-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} headerText={locale.queueVisitTransfer.c} buttons={[{text: locale.queueVisitTransfer.b, cssClass: 'cs-queue-visit-transfer-save', handler: 'cancel'}, {text: locale.queueVisitTransfer.d, cssClass: 'cs-queue-visit-transfer-save', handler: () => Transfer()}]} isOpen={dialog.view === 'queueVisitTransfer'} onClose={CloseDialog}>
            <div className="cs-queue-visit-transfer-helper">
                <p>{locale.queueVisitTransfer.a}</p>
            </div>
            <Select
                animation="slide-down"
                dropdown={false}
                inputStyle="underline"
                rows={locations.length > 10 ? 10 : locations.length}
                display="anchored"
                touchUi={false}
                value={location}
                data={locations}
                onChange={(event) => setLocation(event.value)}
            />
        </Popup>
    );
}

export default QueueVisitTransfer;
