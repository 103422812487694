import React, {useCallback, useRef, useContext, useEffect} from 'react';
import {Popup, toast, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {faLanguage, faUserVneckHair, faRightFromBracket, faMessageQuestion, faBrainCircuit, faUsersViewfinder} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../styles/dashboardHeaderMenu.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderMenu({dialog, Dialog, locale, user, token, wss, setUser, setLocale, configuration}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const menu = useRef([{user: true}, {patients: true}, {ai: true}, {help: true}, {locale: true}, {logout: true}]);

    const SmartDocHealthAI = () => {
        const url = 'https://ai.smartdochealth.com?account=c21hcnRkb2NoZWFsdGg=&handoff=' + window.localStorage.token;
        window.open(url, '_blank');
    }

    const Locale = data => {
        CloseDialog();

        if (data === 'es') {
            setLocale(configuration.locale.es);
            window.localStorage.locale = 'es';
        } else if (data === 'en') {
            setLocale(configuration.locale.en);
            window.localStorage.locale = 'en';
        }

        const item = {...user};
        item.locale = data;
        setUser(item);

        const message = {
            type: 'wss',
            path: 'user-locale',
            action: 'update',
            data: {
                locale: data
            }
        };
        wsCall(message);
    }

    const MenuItem = data => {
        let item = '';

        if (data.item.user) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderProfile', tab: 'account'})} >
                    {user.avatar ? <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + user.avatar + "?d=robohash)"}} className="cs-dashboard-header-menu-avatar" /> : <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faUserVneckHair} />} {locale.dashboardHeaderMenu.b}
                </Button>
            </li>;
        } else if (data.item.patients) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderPatients'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faUsersViewfinder} /> {locale.dashboardHeaderMenu.i}
                </Button>
            </li>;
        } else if (data.item.ai) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => SmartDocHealthAI()} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faBrainCircuit} /> {locale.dashboardHeaderMenu.a}
                </Button>
            </li>;
        } else if (data.item.help) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => Dialog({view: 'dashboardHeaderHelp'})} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faMessageQuestion} /> {locale.dashboardHeaderMenu.f}
                </Button>
            </li>;
        } else if (data.item.locale) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => {user.locale === 'en' ? Locale('es') : Locale('en')}} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon" icon={faLanguage} /> {user.locale === 'en' ? locale.dashboardHeaderMenu.c : locale.dashboardHeaderMenu.e}
                </Button>
            </li>;
        } else if (data.item.logout) {
            item = <li key={data.item.timestamp} className="cs-dashboard-header-menu-li">
                <Button color="primary" variant="flat" className="cs-dashboard-header-menu-button mbsc-bold" onClick={() => Logout()} >
                    <FontAwesomeIcon className="cs-dashboard-header-menu-icon-danger" icon={faRightFromBracket} /> {locale.dashboardHeaderMenu.d}
                </Button>
            </li>;
        }

        return item;
    }

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Logout = useCallback(() => {
        window.localStorage.removeItem('token');
        token.current = '';
        wss.current = '';
        setUser({});

        toast({message: locale.dashboardHeader.i, color: 'info', duration: 1000, display: 'bottom'});
    }, [locale.dashboardHeader.i]);

    const UpdateUserLocale = useCallback(data => {
        if (data.toast) {
            toast({message: locale.dashboardHeader.h, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.dashboardHeader.e]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user-locale') {
            UpdateUserLocale(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUserLocale, setWsResponse]);

  return (
      <Popup className="cs-dashboard-header-menu-popup" display="anchored" anchor={document.getElementById('dashboardHeaderMenu')} buttons={[]} isOpen={dialog.view === 'dashboardHeaderMenu'} onClose={CloseDialog}>
          <Listview
              theme="ios"
              themeVariant="light"
              select="single"
              itemType={MenuItem}
              data={menu.current}
          />
      </Popup>
  );
}

export default DashboardHeaderMenu;
