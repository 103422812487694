import React from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/loginHeader.css';
import {faLanguage} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HeaderLocale from "../dialogs/HeaderLocale";
import HeaderAccount from "../dialogs/HeaderAccount";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginHeader({Dialog, dialog, configuration, locale, select, setLocale}) {

    return (
        <div className="mbsc-grid cs-login-header-toolbar">
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row">
                        <div className="cs-login-header-logo" style={{backgroundImage: `url(${configuration.logo})`}} />
                        <Button onClick={() => Dialog({view: 'headerAccount'})} className="cs-login-header-button-text-logo mbsc-bold" variant="flat" >
                            {configuration.displayName}
                        </Button>
                    </div>
                </div>
                <div className="mbsc-col-auto">
                    <Button id="headerLocale" title={locale.loginHeader.a} onClick={() => Dialog({view: 'headerLocale'})} className="cs-login-header-button-text mbsc-bold" variant="flat" >
                        <FontAwesomeIcon className="cs-login-header-button-icon" icon={faLanguage} />
                    </Button>
                </div>
            </div>
            {dialog.view === 'headerLocale' &&
                <HeaderLocale
                    dialog={dialog}
                    Dialog={Dialog}
                    configuration={configuration}
                    setLocale={setLocale}
                />
            }
            {dialog.view === 'headerAccount' &&
                <HeaderAccount
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    select={select.current}
                />
            }
        </div>
    );
}

export default LoginHeader;
