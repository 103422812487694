import {useCallback, useRef, useState, useEffect} from 'react';
import useWebSocket from "react-use-websocket";
import {toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Decode from './Decode';

function Websockets(locale, token, session, wss) {
    const [wsResponse, setWsResponse] = useState(null);
    const loading = useRef(true);
    const interval = useRef(null);
    const time = useRef(null);

    const {sendJsonMessage} = useWebSocket(wss ? Decode(wss) : null, {
        onOpen: () => {
            loading.current = false;
            StartHeartbeat();
        },
        onClose: () => {},
        onError: () => {
            if (loading.current === false) {
                toast({message: locale.websocket.b, color: 'danger', duration: 3000, display: 'bottom'});
            }},
        onMessage: (event) => {
            const message = JSON.parse(event.data);

            if (message.type === 'heartbeat') {
                time.current = Date.now();
            } else {
                setWsResponse(message);
            }
        },
        retryOnError: true,
        reconnectAttempts: 1000,
        reconnectInterval: 5000,
        share: true
    });

    const wsCall = useCallback((data) => {
        const item = data;
        item.token = token;
        item.session = session;

        sendJsonMessage(item);
    }, [token, session, sendJsonMessage]);

    const StartHeartbeat = () => {
        interval.current = setInterval(() => {
            if (time.current && Date.now() - time.current > 35000) {
                toast({message: locale.websocket.a, color: 'danger', duration: 1000, display: 'bottom'});
                StopHeartbeat();
            } else {
                sendJsonMessage({type: 'heartbeat'});
            }
        }, 30000);
    };

    const StopHeartbeat = () => {
        if (interval.current) {
            clearInterval(interval.current);
            interval.current = null;
        }
    };

    useEffect(() => {
        return () => {
            StopHeartbeat();
        };
    }, []);

    return {wsResponse, wsCall, setWsResponse};
}

export default Websockets;
