import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import '../styles/patientMedications.css';
import {faPrescriptionBottlePill, faPrescriptionBottle, faTrashCan} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import PatientMedication from "../dialogs/PatientMedication";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientMedications({locale, setPatient, patient, dialog, Dialog}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [medications, setMedications] = useState(patient.medications);
    const [medication, setMedication] = useState({});

    const Delete = data => {
        confirm({
            title: locale.patientMedications.g,
            message: locale.patientMedications.h,
            okText: locale.patientMedications.i,
            cancelText: locale.patientMedications.j,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'patient-medication',
                        action: 'delete',
                        data: {
                            patient: patient.id,
                            medications: medications,
                            id: data
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Create = () => {
        setMedication({});
        Dialog({view: 'patientMedication'});
    }

    const Open = data => {
        setMedication(data);
        Dialog({view: 'patientMedication'});
    }

    const Item = data => {
        const itemDate = dayjs(parseInt(data.item.id)).format("MMMM D, YYYY");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-medications-list" onClick={(ev) => Open(data.item)}>
                        {data.item.type === 'active' &&
                            <FontAwesomeIcon className="cs-patient-medications-list-icon-active" icon={faPrescriptionBottle} />
                        }
                        {data.item.type === 'past' &&
                            <FontAwesomeIcon className="cs-patient-medications-list-icon-past" icon={faPrescriptionBottlePill} />
                        }
                        <div className="cs-patient-medications-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.text}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-medications-item-txt">
                                {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-medications-list-button">
                    <Button color="primary" variant="flat" onClick={() => Delete(data.item.id)} >
                        <FontAwesomeIcon className="cs-patient-medications-icon-right" icon={faTrashCan} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const DeletePatientMedication = useCallback(data => {
        if (data.medications) {
            const item = {...patient};
            item.medications = data.medications;
            setPatient(item);
            setMedications(data.medications);
        }
    }, [patient]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'patient-medication') {
            DeletePatientMedication(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeletePatientMedication, setWsResponse]);

    return (
        <div className="cs-patient-medications-page">
            <div className="mbsc-row cs-patient-medications-section cs-patient-medications-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-medications-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientMedications.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <Button color="primary" variant="flat" className="cs-patient-medications-button mbsc-bold mbsc-txt-muted" onClick={() => Create()} >
                        {locale.patientMedications.b}
                    </Button>
                </div>
            </div>
            {medications.length !== 0 ?
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={Item}
                    data={medications}
                />
                :
                <div className="mbsc-row cs-patient-medications-section cs-patient-medications-body" />
            }
            <div className={medications.length === 0 ? "mbsc-row cs-patient-medications-footer-empty" : "mbsc-row cs-patient-medications-footer"} />
            {dialog.view === 'patientMedication' &&
                <PatientMedication
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setPatient={setPatient}
                    setMedications={setMedications}
                    medications={medications}
                    patient={patient}
                    medication={medication}
                />
            }
        </div>
    );
}

export default PatientMedications;
