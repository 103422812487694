import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, Select, setOptions, Textarea, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderHelp.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderHelp({locale, dialog, Dialog}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const [text, setText] = useState('');
  const [priority, setPriority] = useState('low');

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Help = () => {
    if (!text) {
      toast({message: locale.dashboardHeaderHelp.a, color: 'danger', duration: 3000, display: 'bottom'});
    } else {
      toast({message: locale.dashboardHeaderHelp.b, color: 'info', duration: 1000, display: 'bottom'});

      const message = {
        type: 'wss',
        path: 'help',
        action: 'put',
        data: {
          text: text,
          priority: priority
        }
      };
      wsCall(message);
    }
  }

  const PostHelp = useCallback(data => {
    if (data.toast) {
      toast({message: locale.dashboardHeaderHelp.c, color: 'primary', duration: 1000, display: 'bottom'});
      CloseDialog();
    }
  }, [locale.dashboardHeaderHelp.c, CloseDialog]);

  useEffect(() => {
    if (!Empty(wsResponse) && wsResponse.action === 'post' && wsResponse.path === 'help') {
      PostHelp(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, PostHelp, setWsResponse])

  return (
      <Popup className="cs-dashboard-header-help-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} headerText={locale.dashboardHeaderHelp.d} buttons={[{text: locale.dashboardHeaderHelp.k, cssClass: 'cs-dashboard-header-help-send', handler: 'close'}, {text: locale.dashboardHeaderHelp.e, cssClass: 'cs-dashboard-header-help-send', handler: () => Help()}]} isOpen={dialog.view === 'dashboardHeaderHelp'} onClose={CloseDialog}>
          <div className="cs-dashboard-header-help-helper" >
              <p>{locale.dashboardHeaderHelp.g}</p>
          </div>
          <div className="cs-dashboard-header-help-helper" >
              <p>{locale.dashboardHeaderHelp.h}</p>
          </div>
          <div className="cs-dashboard-header-help-helper" >
              <p>{locale.dashboardHeaderHelp.i}</p>
          </div>
          <Select
              animation="slide-down"
              inputStyle="underline"
              dropdown={false}
              label={locale.dashboardHeaderHelp.f}
              labelStyle="floating"
              rows={locale.dashboardHeaderHelp.l.length}
              display="anchored"
              touchUi={false}
              data={locale.dashboardHeaderHelp.l}
              value={priority}
              onChange={(event) => setPriority(event.value)}
          />
          <div className="cs-dashboard-header-help-textarea">
              <Textarea inputStyle="underline" labelStyle="floating" label={locale.dashboardHeaderHelp.j} value={text} onChange={(ev) => setText(ev.target.value)} />
          </div>
      </Popup>
  );
}

export default DashboardHeaderHelp;
