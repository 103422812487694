import React, {useEffect, useState, useCallback, useContext} from 'react';
import {setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/dashboard.css';
import {Chart as ChartJS, CategoryScale, LinearScale, ArcElement, BarElement,  PointElement, LineElement, Title, Tooltip, Legend, Filler, PolarAreaController, RadialLinearScale} from 'chart.js';
import Empty from './utils/Empty';
import Queue from "./pages/Queue";
import Patient from "./pages/Patient";
import DashboardHeader from "./blocks/DashboardHeader";
import {NetworkContext} from "./utils/NetworkContext";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler, PolarAreaController, RadialLinearScale);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function Dashboard({locale, Navigation, navigation, dialog, Dialog, width, configuration, token, wss, setLocale}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [user, setUser] = useState({});
    const [patient, setPatient] = useState({});
    const [visit, setVisit] = useState({});
    const [refresh, setRefresh] = useState(false);

    const Start = () => {
        const message = {
            type: 'wss',
            path: 'user-login',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const GetUserLogin = useCallback(data => {
        if (data.user) {
            setUser(data.user);
        }
        if (configuration.options.queue) {
            Navigation({page:'queue', tab: 'wait'})
        }
    }, [Navigation, configuration.options.queue]);

    const UpdateUser = useCallback(data => {
        if (data.user) {
            setUser(data.user);
        }
        if (data.toast) {
            toast({message: locale.dashboard.a, color: 'info', display: 'bottom', duration: 3000});
        }
        if (refresh) {
            setRefresh(false);
        }
    }, [locale.dashboard.a, refresh]);

    const GetPatient = useCallback(data => {
        if (data.patient) {
            setPatient(data.patient);
        }
        if (data.source && data.source === 'dashboardHeaderPatients') {
            Dialog({view: 'close'});
            Navigation({page: 'patient', tab: 'pastVisits'});
        }
        if (data.source && data.source !== 'dashboardHeaderPatients') {
            Navigation({page: 'patient', tab: 'visit', save: true});
        }
    }, [Navigation]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'patient') {
            GetPatient(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetPatient, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user') {
            UpdateUser(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUser, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user-login') {
            GetUserLogin(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUserLogin, setWsResponse]);

    useEffect(() => {
        Start();
    }, []);

    return (
        <>
            <DashboardHeader
                Navigation={Navigation}
                locale={locale}
                navigation={navigation}
                user={user}
                setUser={setUser}
                dialog={dialog}
                Dialog={Dialog}
                width={width}
                configuration={configuration}
                token={token}
                wss={wss}
                setLocale={setLocale}
                setVisit={setVisit}
                setPatient={setPatient}
            />
            {navigation.page === 'queue' &&
                <Queue
                    locale={locale}
                    width={width}
                    navigation={navigation}
                    Navigation={Navigation}
                    configuration={configuration}
                    dialog={dialog}
                    Dialog={Dialog}
                    user={user}
                    setPatient={setPatient}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    setVisit={setVisit}
                    visit={visit}
                />
            }
            {navigation.page === 'patient' && !Empty(patient) &&
                <Patient
                    locale={locale}
                    width={width}
                    navigation={navigation}
                    Navigation={Navigation}
                    configuration={configuration}
                    dialog={dialog}
                    Dialog={Dialog}
                    patient={patient}
                    setPatient={setPatient}
                    visit={visit}
                    setVisit={setVisit}
                />
            }
        </>
    );
}

export default Dashboard;
