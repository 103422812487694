import React, {useCallback} from 'react';
import {Popup, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/patientVisitTriage.css';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function PatientVisitTriage({locale, dialog, Dialog, visit}) {

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Item = data => {

    return <li key={data.item.sequence}>
      <div className="mbsc-row cs-patient-visit-triage-list">
        <div className="cs-patient-visit-triage-list-section">
          <div className="mbsc-bold mbsc-txt-muted">{data.item.question}</div>
          <div className="mbsc-row mbsc-txt-muted cs-patient-visit-triage-item-txt">
            {data.item.type === 'boolean' && data.item.answer === 'true' && locale.patientVisitTriage.c}
            {data.item.type === 'boolean' && data.item.answer === 'false' && locale.patientVisitTriage.d}
            {(data.item.type === 'number' || data.item.type === 'text') && data.item.answer}
          </div>
        </div>
      </div>
    </li>;
  }

  return (
      <Popup className="cs-patient-visit-triage-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} headerText={locale.patientVisitTriage.b} buttons={[{text: locale.patientVisitTriage.a, cssClass: 'cs-patient-visit-triage-send', handler: 'close'}]} isOpen={dialog.view === 'patientVisitTriage'} onClose={CloseDialog}>
        <Listview
            theme="ios"
            themeVariant="light"
            select="single"
            itemType={Item}
            data={visit.triage}
        />
      </Popup>
  );
}

export default PatientVisitTriage;
