import React, {useCallback, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/queueNewMenu.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faMobileScreenButton, faCirclePhone} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function QueueNewMenu({locale, dialog, Dialog, setType, configuration}) {
  const menu = useRef(configuration.options.whatsapp ? locale.queueNewMenu.e : locale.queueNewMenu.d);

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Select = data => {
    setType(data);
    Dialog({view: 'queueNewCheck'});
  }

  const MenuItem = data => {
    let item = '';

    if (data.item.email) {
      item = <li key={data.item.timestamp} className="cs-queue-new-menu-li">
        <Button color="primary" variant="flat" className="cs-queue-new-menu-button mbsc-bold" onClick={() => Select('mobile')} >
          <FontAwesomeIcon className="cs-queue-new-menu-icon" icon={faMobileScreenButton} /> {locale.queueNewMenu.a}
        </Button>
      </li>;
    } else if (data.item.mobile) {
      item = <li key={data.item.timestamp} className="cs-queue-new-menu-li">
        <Button color="primary" variant="flat" className="cs-queue-new-menu-button mbsc-bold" onClick={() => Select('email')} >
          <FontAwesomeIcon className="cs-queue-new-menu-icon" icon={faAt} /> {locale.queueNewMenu.b}
        </Button>
      </li>;
    } else if (data.item.whatsapp) {
    item = <li key={data.item.timestamp} className="cs-queue-new-menu-li">
      <Button color="primary" variant="flat" className="cs-queue-new-menu-button mbsc-bold" onClick={() => Select('whatsapp')} >
        <FontAwesomeIcon className="cs-queue-new-menu-icon" icon={faCirclePhone} /> {locale.queueNewMenu.c}
      </Button>
    </li>;
  }

    return item;
  }

  return (
      <Popup className="cs-queue-new-menu-popup" anchor={document.getElementById('queueNewMenu')} display="anchored" buttons={[]} isOpen={dialog.view === 'queueNewMenu'} onClose={CloseDialog}>
        <Listview
            theme="ios"
            themeVariant="light"
            select="single"
            itemType={MenuItem}
            data={menu.current}
        />
      </Popup>
  );
}

export default QueueNewMenu;
