import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, toast, Input, Textarea, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/loginHelp.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function LoginHelp({locale, account, dialog, Dialog}) {
  const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
  const [text, setText] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Help = () => {
    if (!text && !email && !name) {
      toast({message: locale.loginHelp.a, color: 'danger', duration: 3000, display: 'bottom'});
    } else {
      toast({message: locale.loginHelp.b, color: 'info', duration: 1000, display: 'bottom'});

      const message = {
        type: 'rest',
        path: 'help',
        action: 'put',
        data: {
          text: text,
          email: email,
          name: name,
          account: account
        }
      };
      restCall(message);
    }
  }

  const PutHelp = useCallback(data => {
    if (data.toast) {
      CloseDialog();
      toast({message: locale.loginHelp.c, color: 'info', duration: 1000, display: 'bottom'});
    }
  }, [locale.loginHelp.c, CloseDialog]);

  useEffect(() => {
    if (restResponse && !Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'help') {
      PutHelp(restResponse.data);
      setRestResponse({});
    }
  }, [restResponse, PutHelp, setRestResponse])

  return (
      <Popup className="cs-login-help-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} display="center" scrollLock={false} headerText={locale.loginHelp.d} buttons={[{text: locale.loginHelp.f, cssClass: 'cs-login-help-send', handler: 'close'}, {text: locale.loginHelp.e, cssClass: 'cs-login-help-send', handler: () => Help()}]} isOpen={dialog.view === 'loginHelp'} onClose={CloseDialog}>
        <div className="cs-login-help-helper" >
          <p>{locale.loginHelp.l}</p>
        </div>
        <div className="cs-login-help-helper" >
          <p>{locale.loginHelp.m}</p>
        </div>
        <div className="cs-login-help-helper" >
          <p>{locale.loginHelp.n}</p>
        </div>
        <Input inputStyle="underline" label={locale.loginHelp.g} labelStyle="floating" type="text" name="name" value={name} onChange={ev => setName(ev.target.value)} />
        <Input inputStyle="underline" label={locale.loginHelp.i} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
        <div className="cs-login-help-textarea">
          <Textarea inputStyle="underline" labelStyle="floating" label={locale.loginHelp.j} value={text} onChange={(ev) => setText(ev.target.value)} />
        </div>
      </Popup>
  );
}

export default LoginHelp;
