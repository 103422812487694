const Mobile = ({data, setMobile, country}) => {
  let formattedText = '';

  if (country === 'us') {
    const cleanedText = data.replace(/[^\d]/g, '');

    if (cleanedText.length <= 3) {
      formattedText = cleanedText;
    } else if (cleanedText.length <= 6) {
      formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3)}`;
    } else {
      formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3, 6)}-${cleanedText.slice(6, 10)}`;
    }
  } else if (country === 'mx') {
    const cleanedText = data.replace(/[^\d]/g, '');

    if (cleanedText.length <= 2) {
      formattedText = cleanedText;
    } else if (cleanedText.length <= 6) {
      formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2)}`;
    } else {
      formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2, 6)}-${cleanedText.slice(6, 10)}`;
    }
  }
  setMobile(formattedText);
};

export default Mobile;
