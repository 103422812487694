import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Input, Popup, setOptions, Textarea} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/patientVisitFile.css';
import Loading from "../utils/Loading";
import dayjs from "dayjs";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientVisitFile({locale, dialog, Dialog, file, visit, setVisit, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [notes, setNotes] = useState(file.notes);
    const [link, setLink] = useState('');
    const [loading, setLoading] = useState(true);

    const CloseDialog = useCallback(() => {
        if (navigation.tab === 'pastVisits') {
            Dialog({view: 'patientPastVisit'});
        } else {
            Dialog({view: 'close'});
        }
    }, [Dialog, navigation.tab]);

    const Save = () => {
        const updatedFiles = visit.files.map(data =>
            data.timestamp === file.timestamp
                ? { ...data, notes: notes }
                : data
        );

        setVisit(prevVisit => ({
            ...prevVisit,
            files: updatedFiles
        }));

        const message = {
            type: 'wss',
            path: 'visit-image',
            action: 'update',
            data: {
                files: updatedFiles,
                user: visit.user
            }
        };
        wsCall(message);
    }

    const Link = data => {
        const message = {
            type: 'wss',
            path: 'visit-image-link',
            action: 'get',
            data: {
                file: file
            }
        };
        wsCall(message);
    }

    const Buttons = () => {
        if (navigation.tab === 'pastVisits') {
            return [{text: locale.patientVisitTriage.a, cssClass: 'cs-patient-visit-file-send', handler: () => CloseDialog()}];
        } else {
            return [{text: locale.patientVisitTriage.e, cssClass: 'cs-patient-visit-file-send', handler: () => notes !== file.notes ? Save() : CloseDialog()}];
        }
    }

    const GetVisitImagelink = useCallback(data => {
        if (data.link) {
            setLink(data.link);
        }
        setLoading(false);
    }, []);

    const UpdateVisitImage = useCallback(data => {
        if (data.toast) {
            CloseDialog();
        }
    }, [CloseDialog]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'visit-image') {
            UpdateVisitImage(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateVisitImage, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'visit-image-link') {
            GetVisitImagelink(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetVisitImagelink, setWsResponse]);

    useEffect(() => {
        Link();
    }, []);

    return (
        <Popup className="cs-patient-visit-file-popup" width={600} maxHeight={900} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} headerText={file.name} buttons={Buttons()} isOpen={dialog.view === 'patientVisitFile'} onClose={CloseDialog}>
            <Input disabled inputStyle="underline" label={locale.patientVisitFile.c} labelStyle="floating" type="text" name="alias" value={dayjs(file.timestamp).format("MMMM D, YYYY @ h:mm A")} />
            {loading &&
                <div className="cs-patient-visit-file-loading mbsc-align-center" >
                    <Loading />
                </div>
            }
            {!loading &&
                <div className="cs-patient-visit-file-image mbsc-align-center">
                    <img src={link}  alt={link} />
                </div>
            }
            {((navigation.tab === 'pastVisits' && notes) || (navigation.tab !== 'pastVisits')) &&
                <div className="cs-patient-visit-file-textarea">
                    <Textarea disabled={navigation.tab === 'pastVisits'} inputStyle="underline" labelStyle="floating" label={locale.patientVisitFile.b} value={notes} onChange={(ev) => setNotes(ev.target.value)} />
                </div>
            }
        </Popup>
    );
}

export default PatientVisitFile;
