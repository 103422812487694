import React, {useCallback, useState, useContext} from 'react';
import {Popup, Input, Checkbox, toast, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {TabNav, NavItem} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderProfile.css';
import Mobile from "../utils/Mobile";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderProfile({locale, dialog, Dialog, user}) {
    const {wsCall} = useContext(NetworkContext);
    const [alias, setAlias] = useState(user.alias);
    const [communicationsSms, setCommunicationsSms] = useState(user.adminCommunications.sms);
    const [communicationsVoice, setCommunicationsVoice] = useState(user.adminCommunications.voice);
    const [communicationsEmail, setCommunicationsEmail] = useState(user.adminCommunications.email);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [country, setCountry] = useState(() => {
        if (user.mobile.startsWith('+1')) {
            return 'us';
        } else if (user.mobile.startsWith('+52')) {
            return 'mx';
        } else {
            return 'us';
        }
    });
    const [mobile, setMobile] = useState(() => {
        if (user.mobile.startsWith('+1')) {
            const number = user.mobile.slice(2);

            return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
        } else if (user.mobile.startsWith('+52')) {
            const number = user.mobile.slice(3);

            return `(${number.slice(0, 2)}) ${number.slice(2, 6)}-${number.slice(6, 10)}`;
        } else {
            return user.mobile;
        }
    });

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Save = () => {
        toast({message: locale.dashboardHeaderProfile.a, color: 'info', display: 'bottom', duration: 1000});
        let number = '';
        if (mobile) {
            number = mobile.replace(/[()\s-]/g, '');
        }
        const adminCommunications = {
            sms: communicationsSms,
            voice: communicationsVoice,
            email: communicationsEmail
        };

        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                alias: alias,
                firstName: firstName,
                lastName: lastName,
                mobile: number,
                email: email,
                country: country,
                adminCommunications: adminCommunications
            }
        };
        wsCall(message);
    }

    return (
        <Popup className="cs-dashboard-header-profile-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} headerText={locale.dashboardHeaderProfile.m} buttons={[{text: locale.dashboardHeaderProfile.u, cssClass: 'cs-dashboard-header-profile-save', handler: 'close'}, {text: locale.dashboardHeaderProfile.l, cssClass: 'cs-dashboard-header-profile-save', handler: () => Dialog({view: 'dashboardHeaderReset'})}, {text: locale.dashboardHeaderProfile.b, cssClass: 'cs-dashboard-header-profile-save', handler: () => Save()}]} isOpen={dialog.view === 'dashboardHeaderProfile'} onClose={CloseDialog}>
            <TabNav theme="ios" themeVariant="light" display='inline' >
                <NavItem selected={dialog.tab === 'account'} onClick={(ev) => Dialog({tab: 'account'})} >
                    <span className={dialog.tab === 'account' ? 'mbsc-bold cs-dashboard-header-profile-nav' : 'mbsc-bold'} >{locale.dashboardHeaderProfile.c}</span>
                </NavItem>
                <NavItem selected={dialog.tab === 'communications'} onClick={(ev) => Dialog({tab: 'communications'})} >
                    <span className={dialog.tab === 'communications' ? 'mbsc-bold cs-dashboard-header-profile-nav' : 'mbsc-bold'} >{locale.dashboardHeaderProfile.d}</span>
                </NavItem>
            </TabNav>
            <div className="mbsc-grid cs-dashboard-header-profile-grid">
                <div className="mbsc-row mbsc-justify-content-center">
                    <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + user.avatar + "?d=robohash)"}} className="cs-dashboard-header-profile-avatar" />
                </div>
                <div className="mbsc-padding mbsc-row mbsc-justify-content-center cs-dashboard-header-profile-link">
                    <a href="https://en.gravatar.com" target="_blank">
                        {locale.dashboardHeaderProfile.n}
                    </a>
                </div>
            </div>
            {dialog.tab === 'account' &&
                <>
                    <Input className="cs-dashboard-header-profile-input" disabled inputStyle="underline" label={locale.dashboardHeaderProfile.r} labelStyle="floating" type="text" name="id" value={user.id} />
                    <Input inputStyle="underline" label={locale.dashboardHeaderProfile.h} labelStyle="floating" type="text" name="alias" value={alias} onChange={ev => setAlias(ev.target.value)} />
                    <Input inputStyle="underline" label={locale.dashboardHeaderProfile.i} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
                    <Input inputStyle="underline" label={locale.dashboardHeaderProfile.j} labelStyle="floating" type="text" name="LastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
                    {user.type !== 'email' &&
                        <Input inputStyle="outline" label={locale.dashboardHeaderProfile.g} labelStyle="floating" type="email" name="email" value={email} onChange={ev => setEmail(ev.target.value)} />
                    }
                    {user.type !== 'mobile' &&
                        <div className="mbsc-grid cs-dashboard-header-profile-grid">
                            <div className="mbsc-row">
                                <div className="mbsc-col-4 cs-dashboard-header-profile-inputs-left">
                                    <Select
                                        animation="slide-down"
                                        dropdown={false}
                                        inputStyle="underline"
                                        label={locale.dashboardHeaderProfile.s}
                                        labelStyle="floating"
                                        rows={locale.dashboardHeaderProfile.t.length}
                                        display="anchored"
                                        touchUi={false}
                                        value={country}
                                        data={locale.dashboardHeaderProfile.t}
                                        disabled={mobile.length !== 0}
                                        onChange={(event) => setCountry(event.value)}
                                    />
                                </div>
                                <div className="mbsc-col-8 cs-dashboard-header-profile-inputs-right">
                                    <Input className="cs-dashboard-header-profile-inputs-right-input" label={locale.dashboardHeaderProfile.k} inputStyle="underline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile({data: ev.target.value, setMobile: setMobile, country: country})} />
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            {dialog.tab === 'communications' &&
                <>
                    <div className="cs-dashboard-header-profile-helper">
                        <p>{locale.dashboardHeaderProfile.o}</p>
                    </div>
                    <div className="cs-dashboard-header-profile-helper">
                        <p>{locale.dashboardHeaderProfile.p}</p>
                    </div>
                    <Checkbox
                        cssClass="cs-dashboard-header-profile-checkbox mbsc-bold"
                        color="info"
                        diabled={user.type !== 'mobile' || !user.mobile}
                        position="start"
                        label={locale.dashboardHeaderProfile.e}
                        checked={communicationsSms}
                        onChange={(ev) => setCommunicationsSms(ev.target.checked)}
                    />
                    <Checkbox
                        cssClass="cs-dashboard-header-profile-checkbox mbsc-bold"
                        color="info"
                        diabled={user.type !== 'mobile' || !user.mobile}
                        position="start"
                        label={locale.dashboardHeaderProfile.f}
                        checked={communicationsVoice}
                        onChange={(ev) => setCommunicationsVoice(ev.target.checked)}
                    />
                    <Checkbox
                        cssClass="cs-dashboard-header-profile-checkbox mbsc-bold"
                        color="info"
                        diabled={user.type !== 'email' || !user.email}
                        position="start"
                        label={locale.dashboardHeaderProfile.g}
                        checked={communicationsEmail}
                        onChange={(ev) => setCommunicationsEmail(ev.target.checked)}
                    />
                </>
            }
        </Popup>
    );
}

export default DashboardHeaderProfile;
