import React from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/microApp.css';
import MicroAppHeader from './blocks/MicroAppHeader';
import QueueWait from "./microapps/QueueWait";
import PatientVisitInstructions from "./microapps/PatientVisitInstructions";
import QueueVisitTriage from "./microapps/QueueVisitTriage";

function MicroApp({locale, setLocale, microApp, navigation, width}) {

    return (
        <>
            <MicroAppHeader
                locale={locale}
                setLocale={setLocale}
                microApp={microApp}
                width={width}
            />
            {navigation.page === 'queueWait' &&
                <QueueWait
                    locale={locale}
                    microApp={microApp}
                    width={width}
                />
            }
            {navigation.page === 'patientVisitInstructions' &&
                <PatientVisitInstructions
                    locale={locale}
                    microApp={microApp}
                    width={width}
                />
            }
            {navigation.page === 'queueVisitTriage' &&
                <QueueVisitTriage
                    locale={locale}
                    microApp={microApp}
                    width={width}
                />
            }
        </>
    );
}

export default MicroApp;
