import React, {useCallback, useContext, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {faWatchFitness} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../styles/patientVitalsMetric.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function PatientVitalsMetric({locale, dialog, Dialog, setMetric, range, patient, date, setLoading}) {
  const {wsCall} = useContext(NetworkContext);
  const menu = useRef(locale.patientVitalsMetric.a);

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Select = data => {
    setMetric(data);
    setLoading(true);

    const message = {
      type: 'wss',
      path: 'vitals-metric',
      action: 'get',
      data: {
        patient: patient.id,
        metric: data,
        range: range,
        date: date
      }
    };
    wsCall(message);
    CloseDialog();
  }

  const Item = data => {
    let item = '';

    if (data.item.temperature) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('temperature')} >
          {locale.patientVitalsMetric.b}
        </Button>
      </li>;
    } else if (data.item.pulse) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('pulse')} >
          {locale.patientVitalsMetric.c}
        </Button>
      </li>;
    } else if (data.item.pulseShared && patient.share && patient.lastSync) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('pulseShared')} >
          {locale.patientVitalsMetric.c}<FontAwesomeIcon className="cs-patient-vitals-metric-icon" icon={faWatchFitness} />
        </Button>
      </li>;
    } else if (data.item.activityShared && patient.share && patient.lastSync) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('activityShared')} >
          {locale.patientVitalsMetric.k}<FontAwesomeIcon className="cs-patient-vitals-metric-icon" icon={faWatchFitness} />
        </Button>
      </li>;
    } else if (data.item.bpShared && patient.share && patient.lastSync) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('bpShared')} >
          {locale.patientVitalsMetric.e}<FontAwesomeIcon className="cs-patient-vitals-metric-icon" icon={faWatchFitness} />
        </Button>
      </li>;
    } else if (data.item.sleepShared && patient.share && patient.lastSync) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('sleepShared')} >
          {locale.patientVitalsMetric.l}<FontAwesomeIcon className="cs-patient-vitals-metric-icon" icon={faWatchFitness} />
        </Button>
      </li>;
    } else if (data.item.rr) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('rr')} >
          {locale.patientVitalsMetric.d}
        </Button>
      </li>;
    } else if (data.item.bp) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('bp')} >
          {locale.patientVitalsMetric.e}
        </Button>
      </li>;
    } else if (data.item.o2Saturation) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('o2Saturation')} >
          {locale.patientVitalsMetric.f}
        </Button>
      </li>;
    } else if (data.item.height) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('height')} >
          {locale.patientVitalsMetric.g}
        </Button>
      </li>;
    } else if (data.item.weight) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('weight')} >
          {locale.patientVitalsMetric.h}
        </Button>
      </li>;
    } else if (data.item.bmi) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('bmi')} >
          {locale.patientVitalsMetric.i}
        </Button>
      </li>;
    } else if (data.item.glucose) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-metric-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-metric-button mbsc-bold" onClick={() => Select('glucose')} >
          {locale.patientVitalsMetric.j}
        </Button>
      </li>;
    }

    return item;
  }

  return (
      <Popup className="cs-patient-vitals-metric-popup" maxHeight={1200} scrollLock={false} anchor={document.getElementById('patientVitalsMetric')} display="anchored" buttons={[]} isOpen={dialog.view === 'patientVitalsMetric'} onClose={CloseDialog}>
        <Listview
            theme="ios"
            themeVariant="light"
            select="single"
            itemType={Item}
            data={menu.current}
        />
      </Popup>
  );
}

export default PatientVitalsMetric;
