import React, {useCallback, useState} from 'react';
import {Input, Popup, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderReset.css';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderReset({locale, dialog, Dialog, setMessage}) {
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'headerProfile'});
    }, [Dialog]);

    function Reset() {
        if (password && password !== confirm) {
            toast({message: locale.dashboardHeaderReset.a, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (password !== confirm) {
            toast({message: locale.dashboardHeaderReset.b, color: 'info', duration: 1000, display: 'bottom'});
        } else {

            const message = {
                type: 'wss',
                path: 'user',
                action: 'update',
                data: {
                    password: password
                }
            };
            setMessage(message);
        }
    }

    return (
        <Popup className="cs-dashboard-header-reset-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} headerText={locale.dashboardHeaderReset.c} buttons={[{text: locale.dashboardHeaderReset.k, cssClass: 'cs-dashboard-header-reset-save', handler: 'cancel'}, {text: locale.dashboardHeaderReset.f, cssClass: 'cs-dashboard-header-reset-save', handler: () => Reset()}]} isOpen={dialog.view === 'dashboardHeaderReset'} onClose={CloseDialog}>
            <div className="cs-dashboard-header-reset-helper">
                <p>{locale.dashboardHeaderReset.g}</p>
            </div>
            <div className="cs-dashboard-header-reset-helper">
                <p>{locale.dashboardHeaderReset.h}</p>
            </div>
            <div className="cs-dashboard-header-reset-helper">
                <p>{locale.dashboardHeaderReset.i}</p>
            </div>
            <div className="cs-dashboard-header-reset-helper">
                <p>{locale.dashboardHeaderReset.j}</p>
            </div>
            <Input inputStyle="underline" label={locale.dashboardHeaderReset.d} labelStyle="floating" type="password" name="password" passwordToggle={true} value={password} onChange={ev => setPassword(ev.target.value)} />
            <Input inputStyle="underline" label={locale.dashboardHeaderReset.e} labelStyle="floating" type="password" name="confirm" passwordToggle={true} value={confirm} onChange={ev => setConfirm(ev.target.value)} />
        </Popup>
    );
}

export default DashboardHeaderReset;
