import React, {useState, useContext} from 'react';
import {setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import BalloonBlock from "@ckeditor/ckeditor5-build-balloon-block";
import '../styles/patientMedicalHistory.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientMedicalHistory({locale, setPatient, patient, setDraft}) {
    const {wsCall} = useContext(NetworkContext);
    const [pmh, setPmh] = useState(patient.pmh);

    const Change = data => {
        setPmh(data);

        if (data && data.length % 10 === 0) {
            const item = {...patient};
            item.pmh = data;
            setPatient(item);

            const message = {
                type: 'wss',
                path: 'patient',
                action: 'update',
                data: {
                    patient: patient.id,
                    pmh: data
                }
            };
            wsCall(message);
        }
    }

    const Blur = () => {
        setDraft(false);

        const item = {...patient};
        item.pmh = pmh;
        setPatient(item);

        const message = {
            type: 'wss',
            path: 'patient',
            action: 'update',
            data: {
                patient: patient.id,
                pmh: pmh
            }
        };
        wsCall(message);
    }

    return (
        <div className="cs-patient-medical-history-page">
            <div className="mbsc-row cs-patient-medical-history-section cs-patient-medical-history-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-medical-history-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientMedicalHistory.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" />
            </div>
            <CKEditor
                id="editor-focus"
                config={{
                    toolbar: ['bold', 'italic', 'link'],
                    blockToolbar: ['heading', '|', 'bold', 'italic', 'link', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                }}
                editor={BalloonBlock}
                data={pmh}
                onChange={(event, editor) => Change(editor.getData())}
                onBlur={() => pmh !== patient.pmh ? Blur() : null}
            />
            <div className="mbsc-row cs-patient-medical-history-footer" />
        </div>
    );
}

export default PatientMedicalHistory;
