import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import '../styles/patientImmunizations.css';
import {faShieldVirus, faTrashCan} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import PatientImmunization from "../dialogs/PatientImmunization";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientImmunizations({locale, setPatient, patient, width, dialog, Dialog}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [immunizations, setImmunizations] = useState(patient.immunizations);
    const [immunization, setImmunization] = useState({});

    const Delete = data => {
        confirm({
            title: locale.patientImmunizations.g,
            message: locale.patientImmunizations.h,
            okText: locale.patientImmunizations.i,
            cancelText: locale.patientImmunizations.j,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'patient-immunization',
                        action: 'delete',
                        data: {
                            patient: patient.id,
                            immunizations: immunizations,
                            id: data
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Create = () => {
        setImmunization({});
        Dialog({view: 'patientImmunization'});
    }

    const Open = data => {
        setImmunization(data);
        Dialog({view: 'patientImmunization'});
    }

    const Item = data => {
        const itemDate = dayjs(data.item.date).format("MMMM D, YYYY");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-immunizations-list" onClick={(ev) => Open(data.item)}>
                        <FontAwesomeIcon className="cs-patient-immunizations-list-icon" icon={faShieldVirus} />
                        <div className="cs-patient-immunizations-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.text}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-immunizations-item-txt">
                                {locale.patientImmunizations.d}: {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-immunizations-list-button">
                    <Button color="primary" variant="flat" onClick={() => Delete(data.item.id)} >
                        <FontAwesomeIcon className="cs-patient-immunizations-icon-right" icon={faTrashCan} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const DeletePatientImmunization = useCallback(data => {
        if (data.immunizations) {
            const item = {...patient};
            item.immunizations = data.immunizations;
            setPatient(item);
            setImmunizations(data.immunizations);
        }
    }, [patient]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'patient-immunization') {
            DeletePatientImmunization(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeletePatientImmunization, setWsResponse]);

    return (
        <div className="cs-patient-immunizations-page">
            <div className="mbsc-row cs-patient-immunizations-section cs-patient-immunizations-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-immunizations-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientImmunizations.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <Button color="primary" variant="flat" className="cs-patient-immunizations-button mbsc-bold mbsc-txt-muted" onClick={() => Create()} >
                        {locale.patientImmunizations.b}
                    </Button>
                </div>
            </div>
            {immunizations.length !== 0 ?
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={Item}
                    data={immunizations}
                />
                :
                <div className="mbsc-row cs-patient-immunizations-section cs-patient-immunizations-body" />
            }
            <div className={immunizations.length === 0 ? "mbsc-row cs-patient-immunizations-footer-empty" : "mbsc-row cs-patient-immunizations-footer"} />
            {dialog.view === 'patientImmunization' &&
                <PatientImmunization
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    setPatient={setPatient}
                    setImmunizations={setImmunizations}
                    immunizations={immunizations}
                    patient={patient}
                    width={width}
                    immunization={immunization}
                />
            }
        </div>
    );
}

export default PatientImmunizations;
