import React, {useCallback, useState, useEffect, useContext} from 'react';
import {RadioGroup, Radio, toast, Button, setOptions, Textarea, Input} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {BottomNav, NavItem} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/microAppQueueVisitTriage.css';
import Loading from "../utils/Loading";
import Empty from "../utils/Empty";
import {NetworkContext} from '../utils/NetworkContext';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function QueueVisitTriage({locale, microApp, width}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [step, setStep] = useState(0);
    const [triage, setTriage] = useState([]);
    const [loading, setLoading] = useState(false);

    const Triage = () => {
        setLoading(true);

        const message = {
            type: 'rest',
            path: 'visit-triage',
            action: 'get',
            data: {
                user: microApp.user,
                microApp: {
                    account: microApp.account,
                    location: microApp.location,
                    specialty: microApp.specialty,
                    patients: microApp.patients
                }
            }
        };
        restCall(message);
    }

    const Update = (index, value) => {
        setTriage(prevTriage => {
            const newTriage = [...prevTriage];
            newTriage[index] = { ...newTriage[index], answer: value };
            return newTriage;
        });
    }

    const Next = (data, value) => {
        if (data === 'text' && !value) {
            toast({message: locale.microAppQueueVisitTriage.e, color: 'danger', display: 'top', duration: 3000});
        } else {
            setStep(step + 1);
        }
    }

    const Back = () => {
        const item = step - 1;
        setStep(item);
    }

    const Complete = (data, value) => {
        if (data === 'text' && !value) {
            toast({message: locale.microAppQueueVisitTriage.j, color: 'danger', display: 'top', duration: 3000});
        } else {
            setLoading(true);

            const message = {
                type: 'rest',
                path: 'queue-triage',
                action: 'update',
                data: {
                    user: microApp.user,
                    triage: triage,
                    microApp: {
                        account: microApp.account,
                        location: microApp.location
                    }
                }
            };
            restCall(message);
        }
    }

    const GetVisitTriage = useCallback(data => {
        if (data.triage) {
            setTriage(data.triage);
            setLoading(false);
        }

    }, []);

    const UpdateQueueTriage = useCallback(data => {
        if (data.toast) {
            setStep(step + 1);
            setLoading(false);
        }
    }, [step]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'queue-triage') {
            UpdateQueueTriage(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateQueueTriage, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'visit-triage') {
            GetVisitTriage(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetVisitTriage, setRestResponse]);

    return (
        <div className={width > 768 ? "mbsc-grid cs-microapp-queue-visit-triage-main" : "mbsc-grid cs-microapp-queue-visit-triage-main-small"} >
            <div className="mbsc-row mbsc-justify-content-center">
                <div className="mbsc-col-sm-12 mbsc-col-md-11 mbsc-col-xl-10 cs-microapp-queue-visit-triage-page">
                    {triage.length === 0 && !loading &&
                        <div className="cs-microapp-queue-visit-triage-helper mbsc-align-center" >
                            <h3>{locale.microAppQueueVisitTriage.b}</h3>
                        </div>
                    }
                    {loading &&
                        <>
                            <div className="cs-microapp-queue-visit-triage-helper mbsc-align-center" >
                                <h3>{locale.microAppQueueVisitTriage.a}</h3>
                            </div>
                            <div className="cs-microapp-queue-visit-triage-loading mbsc-align-center" >
                                <Loading />
                            </div>
                        </>
                    }
                    {!loading && triage.length !== 0 && triage.map((item, index) => {
                        return (
                            <>
                                {step === index &&
                                    <>
                                        <div className="cs-microapp-queue-visit-triage-helper mbsc-align-center" >
                                            <h3>{item.question}</h3>
                                        </div>
                                        {item.type === 'text' &&
                                            <div className="cs-microapp-queue-visit-triage-textarea">
                                                <Textarea className="mbsc-txt-l" inputStyle="underline" labelStyle="floating" value={item.answer} onChange={(ev) => Update(index, ev.target.value)} />
                                            </div>
                                        }
                                        {item.type === 'number' &&
                                            <Input className="cs-microapp-queue-visit-triage-input-triage mbsc-txt-l" inputStyle="underline" labelStyle="floating" type="number" name="number" value={item.answer} onChange={ev => Update(index, ev.target.value)} />
                                        }
                                        {item.type === 'boolean' &&
                                            <RadioGroup name={item.sequence} >
                                                <Radio className={item.answer === "true" ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueVisitTriage.c} value="true" checked={item.answer === "true"} onChange={ev => Update(index, ev.target.value)} />
                                                <Radio className={item.answer === "false" ? 'mbsc-bold mbsc-txt-m' : 'mbsc-txt-m'} label={locale.microAppQueueVisitTriage.d} value="false" checked={item.answer === "false"} onChange={ev => Update(index, ev.target.value)} defaultChecked={true} />
                                            </RadioGroup>
                                        }
                                        {width > 768 &&
                                            <div className="mbsc-row cs-microapp-queue-visit-triage-section">
                                                {step !== 0 &&
                                                    <div className="mbsc-col mbsc-align-center">
                                                        <Button color="primary" className="cs-microapp-queue-visit-triage-button mbsc-txt-l mbsc-bold" onClick={() => Back()} >
                                                            {locale.microAppQueueVisitTriage.i}
                                                        </Button>
                                                    </div>
                                                }
                                                {step !== (triage.length - 1) &&
                                                    <div className="mbsc-col mbsc-align-center">
                                                        <Button color="primary" className="cs-microapp-queue-visit-triage-button mbsc-txt-l mbsc-bold" onClick={() => Next(item.type, item.answer)} >
                                                            {locale.microAppQueueVisitTriage.h}
                                                        </Button>
                                                    </div>
                                                }
                                                {step === (triage.length - 1) &&
                                                    <div className="mbsc-col mbsc-align-center">
                                                        <Button color="primary" className="cs-microapp-queue-visit-triage-button mbsc-txt-l mbsc-bold" onClick={() => Complete(item.type, item.answer)} >
                                                            {locale.microAppQueueVisitTriage.k}
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {width < 768 &&
                                            <div className="mbsc-row">
                                                <div className="mbsc-col cs-microapp-queue-visit-triage-grid cs-microapp-queue-visit-triage-nav-bottom" >
                                                    <BottomNav
                                                        theme="ios"
                                                        themeVariant="light"
                                                        type="bottom"
                                                    >
                                                        {step !== 0 &&
                                                            <NavItem>
                                                                <Button color="primary" className="cs-microapp-queue-visit-triage-button-bottom mbsc-txt-l mbsc-bold" onClick={() => Back()} >
                                                                    {locale.microAppQueueVisitTriage.i}
                                                                </Button>
                                                            </NavItem>
                                                        }
                                                        {step !== (triage.length - 1) &&
                                                            <NavItem>
                                                                <Button color="primary" className="cs-microapp-queue-visit-triage-button-bottom mbsc-txt-l mbsc-bold" onClick={() => Next(item.type, item.answer)} >
                                                                    {locale.microAppQueueVisitTriage.h}
                                                                </Button>
                                                            </NavItem>
                                                        }
                                                        {step === (triage.length - 1) &&
                                                            <NavItem>
                                                                <Button color="primary" className="cs-microapp-queue-visit-triage-button-bottom mbsc-txt-l mbsc-bold" onClick={() => Complete(item.type, item.answer)} >
                                                                    {locale.microAppQueueVisitTriage.k}
                                                                </Button>
                                                            </NavItem>
                                                        }
                                                    </BottomNav>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        );
                    })}
                    {!loading && triage.length !== 0 && step === triage.length &&
                        <div className="cs-microapp-queue-visit-triage-helper mbsc-align-center" >
                            <h3>{locale.microAppQueueVisitTriage.g}</h3>
                        </div>
                    }
                    {!loading && width > 768 && triage.length === 0 &&
                        <div className="mbsc-row cs-microapp-queue-visit-triage-section">
                            <div className="mbsc-col mbsc-button-group-block">
                                <Button color="primary" className="cs-microapp-queue-visit-triage-button mbsc-txt-l mbsc-bold" onClick={() => Triage()} >
                                    {locale.microAppQueueVisitTriage.h}
                                </Button>
                            </div>
                        </div>
                    }
                    {!loading && width < 768 && triage.length === 0 &&
                        <div className="mbsc-row">
                            <div className="mbsc-col cs-microapp-queue-visit-triage-grid cs-microapp-queue-visit-triage-nav-bottom" >
                                <BottomNav
                                    theme="ios"
                                    themeVariant="light"
                                    type="bottom"
                                >
                                    <NavItem>
                                        <Button color="primary" className="cs-microapp-queue-visit-triage-button-bottom mbsc-txt-l mbsc-bold" onClick={() => Triage()} >
                                            {locale.microAppQueueVisitTriage.h}
                                        </Button>
                                    </NavItem>
                                </BottomNav>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default QueueVisitTriage;
