import React, {useCallback} from 'react';
import {Popup, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/sharedQr.css';
import QRCode from "react-qr-code";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function QR({locale, dialog, Dialog, qr}) {

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    return (
        <Popup className="cs-shared-qr-popup" width={375} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} headerText={qr.title} buttons={[{text: locale.sharedQr.a, cssClass: 'cs-shared-qr-save', handler: 'cancel'}]} isOpen={dialog.view === 'sharedQr'} onClose={CloseDialog}>
            <div className="mbsc-row mbsc-justify-content-center cs-shared-qr-qr">
                <QRCode
                    size={256}
                    value={qr.url}
                    viewBox={`0 0 256 256`}
                />
            </div>
        </Popup>
    );
}

export default QR;
