import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Input, toast, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/patientMedication.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientMedication({locale, dialog, Dialog, setPatient, setMedications, medications, patient, medication}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [type, setType] = useState(medication.type ? medication.type : 'active');
    const [text, setText] = useState(medication.text ? medication.text : '');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Add = () => {
        if (!text) {
            toast({message: locale.patientMedication.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-medication',
                action: 'put',
                data: {
                    patient: patient.id,
                    medications: medications,
                    type: type,
                    text: text
                }
            };
            wsCall(message);
        }
    }

    const Header = () => {
        if (!Empty(medication) && medication.id) {
            return locale.patientMedication.h;
        } else {
            return locale.patientMedication.b;
        }
    }

    const Update = () => {
        if (!text) {
            toast({message: locale.patientMedication.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-medication',
                action: 'update',
                data: {
                    patient: patient.id,
                    medications: medications,
                    type: type,
                    text: text,
                    id: medication.id
                }
            };
            wsCall(message);
        }
    }

    const Buttons = () => {
        if (!Empty(medication) && medication.id) {
            return [{text: locale.patientMedication.g, cssClass: 'cs-patient-medication-popup-save', handler: 'close'}, {text: locale.patientMedication.i, cssClass: 'cs-patient-medication-popup-save', handler: () => Update()}];
        } else {
            return [{text: locale.patientMedication.g, cssClass: 'cs-patient-medication-popup-save', handler: 'close'}, {text: locale.patientMedication.a, cssClass: 'cs-patient-medication-popup-save', handler: () => Add()}];
        }
    }

    const PutPatientMedication = useCallback(data => {
        if (data.medications) {
            const item = {...patient};
            item.medications = data.medications;
            setPatient(item);
            setMedications(data.medications);
            setText('');
            CloseDialog();
        }
    }, [patient, CloseDialog]);

    const UpdatePatientMedication = useCallback(data => {
        if (data.medications) {
            const item = {...patient};
            item.medications = data.medications;
            setPatient(item);
            setMedications(data.medications);
            setText('');
            CloseDialog();
        }
    }, [patient, CloseDialog]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'patient-medication') {
            UpdatePatientMedication(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdatePatientMedication, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'patient-medication') {
            PutPatientMedication(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutPatientMedication, setWsResponse]);

    return (
        <Popup className="cs-patient-medication-popup" display="center" width={600} closeOnOverlayClick={false} closeOnEsc={false} headerText={Header()} buttons={Buttons()} isOpen={dialog.view === 'patientMedication'} onClose={CloseDialog}>
            <div className="mbsc-grid cs-patient-medication-grid">
                <div className="mbsc-row">
                    <div className="mbsc-col-4 cs-patient-medication-inputs-left">
                        <Select
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="underline"
                            label={locale.patientMedication.d}
                            labelStyle="floating"
                            rows={locale.patientMedication.c.length}
                            display="anchored"
                            touchUi={false}
                            value={type}
                            data={locale.patientMedication.c}
                            onChange={(event) => setType(event.value)}
                        />
                    </div>
                    <div className="mbsc-col-8 cs-patient-medication-inputs-right">
                        <Input className="cs-patient-medication-inputs-right-input" label={locale.patientMedication.e} inputStyle="underline" labelStyle="floating" type="text" name="allergy" value={text} onChange={ev => setText(ev.target.value)} />
                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default PatientMedication;
