import React, {useContext, useEffect, useCallback, useState} from 'react';
import {Button, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import BalloonBlock from "@ckeditor/ckeditor5-build-balloon-block";
import '../styles/patientPastVisits.css';
import {faClipboardList} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from "../utils/Empty";
import Loading from "../utils/Loading";
import PatientPastVisit from "../dialogs/PatientPastVisit";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientPastVisits({locale, patient, visits, setVisits, summary, setSummary, dialog, Dialog, width, setFile}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [visit, setVisit] = useState({});
    const [loading, setLoading] = useState(false);

    const Summary = () => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'visits-summary',
            action: 'get',
            data: {
                user: patient.id
            }
        };
        wsCall(message);
    }

    const Visits = () => {
        const message = {
            type: 'wss',
            path: 'visits',
            action: 'get',
            data: {
                user: patient.id
            }
        };
        wsCall(message);
    }

    const Open = data => {
        const message = {
            type: 'wss',
            path: 'queue-visit',
            action: 'get',
            data: {
                user: patient.id,
                id: data.id
            }
        };
        wsCall(message);
    }

    const Item = data => {
        const itemDate = dayjs(parseInt(data.item.id)).format("MMMM D, YYYY @ h:mm A");

        return <li key={data.item.id}>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-patient-past-visits-list" onClick={(ev) => Open(data.item)}>
                        <FontAwesomeIcon className="cs-patient-past-visits-list-icon" icon={faClipboardList} />
                        <div className="cs-patient-past-visits-list-section">
                            <div className="mbsc-bold mbsc-txt-muted">{data.item.reason}</div>
                            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-patient-past-visits-item-txt">
                                {locale.patientPastVisits.c} {itemDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-patient-past-visits-list-button" />
            </div>
        </li>;
    }

    const GetVisitsSummary = useCallback(data => {
        if (data.summary) {
            setSummary(data.summary);
        }
        if (data.error) {
            toast({message: locale.patientPastVisits.d, color: 'danger', display: 'bottom', duration: 3000});
        }
        setLoading(false);
    }, [locale.patientPastVisits.d]);

    const GetVisits = useCallback(data => {
        if (data.visits) {
            setVisits(data.visits);
        }
    }, []);

    const GetQueueVisit = useCallback(data => {
        if (data.visit) {
            setVisit(data.visit);
            let tab = '';
            if (data.visit.soap.assessment) {
                tab = 'assessment';
            } else if (data.visit.soap.plan) {
                tab = 'plan';
            } else if (data.visit.soap.objective) {
                tab = 'objective';
            } else if (data.visit.soap.subjective) {
                tab = 'subjective';
            } else if (data.visit.soap.instructions) {
                tab = 'instructions';
            } else if (data.visit.triage && data.visit.triage.length !== 0) {
                tab = 'triage';
            } else if (data.visit.files && data.visit.files.length !== 0) {
                tab = 'files';
            }
            if (data.visit.files.length === 0 && data.visit.triage.length === 0 && !data.visit.soap.assessment && !data.visit.soap.plan && !data.visit.soap.objective && !data.visit.soap.subjective && !data.visit.soap.instructions) {
                toast({message: locale.patientPastVisits.e, color: 'danger', display: 'bottom', duration: 3000});
            } else {
                Dialog({view: 'patientPastVisit', tab: tab});
            }
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'queue-visit') {
            GetQueueVisit(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetQueueVisit, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'visits-summary') {
            GetVisitsSummary(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetVisitsSummary, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'visits') {
            GetVisits(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetVisits, setWsResponse]);

    useEffect(() => {
        Visits();
    }, []);

    return (
        <div className="cs-patient-past-visits-page">
            <div className="mbsc-row cs-patient-past-visits-section cs-patient-past-visits-title">
                <div className="mbsc-col" >
                    <div className="cs-patient-past-visits-title-text">
                        <p className="mbsc-txt-muted mbsc-bold">{locale.patientPastVisits.a}</p>
                    </div>
                </div>
                <div className="mbsc-col-auto" >
                    <div className="mbsc-row" >
                        <Button disabled={visits.length === 0} color="primary" variant="flat" className="cs-patient-past-visits-button mbsc-bold mbsc-txt-muted" onClick={() => Summary()} >
                            {locale.patientPastVisits.b}
                        </Button>
                    </div>
                </div>
            </div>
            {summary &&
                <CKEditor
                    id="editor-summary"
                    onReady={editor => {editor.enableReadOnlyMode('editor-summary')}}
                    editor={BalloonBlock}
                    data={summary}
                    disableWatchdog={true}
                />
            }
            {loading &&
                <div className="cs-patient-past-visits-loading" >
                    <Loading />
                </div>
            }
            {visits.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={Item}
                    data={visits}
                />
            }
            <div className={visits.length === 0 ? "mbsc-row cs-patient-past-visits-footer-empty" : "mbsc-row cs-patient-past-visits-footer"} />
            {dialog.view === 'patientPastVisit' && !Empty(visit) &&
                <PatientPastVisit
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    visit={visit}
                    setVisit={setVisit}
                    width={width}
                    setFile={setFile}
                />
            }
        </div>
    );
}

export default PatientPastVisits;
