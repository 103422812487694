import React, {useState , useEffect, useRef, useCallback} from 'react';
import {Page, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import './styles/app.css';
import Login from './Login';
import MicroApp from './MicroApp';
import Register from './Register';
import Dashboard from './Dashboard';
import English from './locale/english.json';
import Spanish from './locale/spanish.json';
import {NetworkProvider} from './utils/NetworkProvider';
dayjs.extend(utc);
dayjs.extend(timezone);

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

const Token = () => {
  let data = window.localStorage.token;
  if (!data) {
    data = '';
  }
  return data;
}

const Account = () => {
  const itemDomain = window.location.hostname;
  const itemParts = itemDomain.split('.');
  const itemSaved = window.localStorage.account;

  let itemName = '';

  if (itemSaved) {
    itemName = itemSaved;
  } else if (itemParts.length > 2) {
    itemName = itemParts[itemParts.length - 2];
  }

  return itemName;
}

function Session() {
  const itemMinutes = dayjs().utcOffset();
  const itemHours = (itemMinutes >= 0 ? '+' : '-') + String(Math.floor(Math.abs(itemMinutes) / 60)).padStart(2, '0') + ':' + String(Math.abs(itemMinutes) % 60).padStart(2, '0');

  return {
    navigator: {
      agent: navigator.userAgent,
      language: navigator.language,
      referrer: document.referrer
    },
    id: Math.floor(Math.random() * 1000000000000),
    version: "1.0",
    app: "eyJhbGciOiJIUzI1NiJ9.U21hcnREb2NIZWFsdGg.bH6kzmYF7ssmV8Oi_m1TOiN4QysKS-AxVCunqIeV8Lo",
    timezone: dayjs.tz.guess(),
    offset: itemHours
  };
}

function App() {
  const token = useRef(Token());
  const session = useRef(Session());
  const wss = useRef('');
  const select = useRef(false);
  const account = useRef(Account());
  const reset = useRef('');
  const shortener = useRef('');
  const application = useRef('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [configuration, setConfiguration] = useState({});
  const [locale, setLocale] = useState(English);
  const [navigation, setNavigation] = useState({});
  const [dialog, setDialog] = useState({});
  const [microApp, setMicroApp] = useState({});

  const Width = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    setHeight(height);
    setWidth(width);
  }

  const Navigation = useCallback(data => {
    const current = {...navigation};
    let back = JSON.parse(JSON.stringify(navigation.back));

    if (data.menu) {
      current.menu = data.menu;
      setNavigation(current);
    } else if (data.back) {
      setNavigation(back[0]);
    } else {
      let view = data.view ? data.view : '';
      let page = data.page ? data.page : '';
      let tab = data.tab ? data.tab : '';
      let item = data.item ? data.item : '';

      if (page && !view) {
        view = current.view;
      } else if (tab && !view && !page) {
        view = current.view;
        page = current.page;
      } else if (item && !view && !page && !tab) {
        view = current.view;
        page = current.page;
        tab = current.tab;
      }

      if (data.save) {
        back.unshift(current);
      }

      const object = {
        menu: current.menu,
        view: view,
        page: page,
        tab: tab,
        item: item,
        back: back
      }
      setNavigation(object);
    }
  }, [navigation]);

  const Dialog = useCallback(data => {
    let update = data;
    let current = dialog;

    let view = '';
    let tab = '';

    if (update.view && update.view === 'close') {
      view = '';
      tab = '';
    } else if (update.view || update.tab) {
      view = current.view;

      if (update.view) {
        view = update.view;
      } else if (current.view) {
        view = current.view;
      }
      if (update.tab) {
        tab = update.tab;
      } else if (current.tab) {
        tab = current.tab;
      }
    }
    let object = {
      view: view,
      tab: tab
    }
    setDialog(object);
  }, [dialog]);

  const Locale = () => {
    if (window.localStorage.locale && window.localStorage.locale === 'en') {
      setLocale(English);
    } else if (window.localStorage.locale && window.localStorage.locale === 'es') {
      setLocale(Spanish);
    } else {
      window.localStorage.locale = 'en';
      setLocale(English);
    }
  };

  useEffect(() => {
    let location = window.location.href;
    const url = new URL(location);
    location = window.location;

    const itemFragment = url.hash.substring(1);
    if (itemFragment) {
      shortener.current = itemFragment;
    }

    const itemApplication = location.hostname.split('.');
    if (itemApplication.length > 2) {
      application.current = itemApplication[0];
    }

    const itemShortener = url.searchParams.get('s');
    if (itemShortener) {
      shortener.current = itemShortener;
    }

    const itemToken = url.searchParams.get('token');
    if (itemToken) {
      reset.current = itemToken;
    }

    const itemSelect = url.searchParams.get('select');
    if (itemSelect) {
      select.current = true;
    }

    const itemLocale = url.searchParams.get('locale');
    if (itemLocale === 'en') {
      setLocale(English);
      window.localStorage.locale = 'en';
    } else if (itemLocale === 'es') {
      setLocale(Spanish);
      window.localStorage.locale = 'es';
    } else {
      Locale();
    }

    window.history.replaceState(null, "SmartDocHealth", "/");
    document.title = 'SmartDocHealth';
    setNavigation({view: 'login'})

    Width();
    window.addEventListener('resize', Width);
    return () => window.removeEventListener('resize', Width);
  }, []);

  return (
      <NetworkProvider locale={locale} session={session.current} token={token.current} wss={wss.current}>
        <Page>
          {navigation.view === 'login' &&
              <Login
                  token={token}
                  wss={wss}
                  locale={locale}
                  Navigation={Navigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  account={account}
                  configuration={configuration}
                  reset={reset}
                  setConfiguration={setConfiguration}
                  setLocale={setLocale}
                  select={select}
                  setDialog={setDialog}
                  setNavigation={setNavigation}
                  shortener={shortener}
                  setMicroApp={setMicroApp}
              />
          }
          {navigation.view === 'microApp' &&
              <MicroApp
                  locale={locale}
                  setLocale={setLocale}
                  microApp={microApp}
                  navigation={navigation}
                  width={width}
              />
          }
          {navigation.view === 'register' &&
              <Register
                  locale={locale}
                  Navigation={Navigation}
                  account={account.current}
              />
          }
          {navigation.view === 'dashboard' &&
              <Dashboard
                  locale={locale}
                  Navigation={Navigation}
                  navigation={navigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  width={width}
                  configuration={configuration}
                  token={token}
                  wss={wss}
                  setLocale={setLocale}
              />
          }
        </Page>
      </NetworkProvider>
  );
}

export default App;
