import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, toast, Input, Textarea, Datepicker, Select, Button, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview, NavItem, TabNav} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/queueVisit.css';
import Empty from "../utils/Empty";
import User from "../utils/User";
import Loading from "../utils/Loading";
import dayjs from 'dayjs';
import QRCode from "react-qr-code";
import {faBracketsSquare, faRightToBracket, faLeftFromBracket, faDownFromBracket, faTrashCan} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function QueueVisit({locale, configuration, dialog, Dialog, width, visit, setRefresh, navigation, user, setQr, qr}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const [firstName, setFirstName] = useState(visit.firstName ? visit.firstName : '');
  const [middleName, setMiddleName] = useState(visit.middleName ? visit.middleName : '');
  const [lastName, setLastName] = useState(visit.lastName ? visit.lastName : '');
  const [paternalName, setPaternalName] = useState(visit.paternalName ? visit.paternalName : '');
  const [maternalName, setMaternalName] = useState(visit.maternalName ? visit.maternalName : '');
  const [dob, setDob] = useState(visit.dob ? visit.dob : '');
  const [gender, setGender] = useState(visit.gender ? visit.gender : 'male');
  const [reason, setReason] = useState(visit.reason ? visit.reason : '');
  const [notes, setNotes] = useState(visit.notes ? visit.notes : '');
  const [toSave, setToSave] = useState(false);

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Triage = data => {
    setQr({});

    const message = {
      type: 'wss',
      path: 'shortener',
      action: 'put',
      data: {
        path: 'queueVisitTriage',
        type: 'qr',
        user: visit.user,
        arrival: visit.timestampArrival,
        title: locale.queueVisit.aj,
        location: visit.location
      }
    };
    wsCall(message);
  }

  const Instructions = data => {
    setQr({});

    const message = {
      type: 'wss',
      path: 'shortener',
      action: 'put',
      data: {
        path: 'patientVisitInstructions',
        type: 'qr',
        user: visit.user,
        arrival: visit.timestampArrival,
        title: locale.queueVisit.al
      }
    };
    wsCall(message);
  }

  const Delete = () => {
    confirm({
      title: locale.queueVisit.y,
      message: locale.queueVisit.z,
      okText: locale.queueVisit.x,
      cancelText: locale.queueVisit.aa,
      callback: (res) => {
        if (res) {
          setRefresh(true);

          const message = {
            type: 'wss',
            path: 'visit',
            action: 'delete',
            data: {
              user: visit.user,
            }
          };
          wsCall(message);
        }
      }
    });
  }

  const Status = data => {
    if (data === 'discharge') {
      confirm({
        title: locale.queueVisit.ac,
        message: locale.queueVisit.ad,
        okText: locale.queueVisit.ae,
        cancelText: locale.queueVisit.aa,
        callback: (res) => {
          if (res) {
            setRefresh(true);

            const message = {
              type: 'wss',
              path: 'visit-status',
              action: 'update',
              data: {
                user: visit.user,
                status: data,
                log: visit.log
              }
            };
            wsCall(message);
          }
        }
      });
    } else if (data === 'transfer' && configuration.locations.length === 2) {
      confirm({
        title: locale.queueVisit.af,
        message: locale.queueVisit.ag,
        okText: locale.queueVisit.ah,
        cancelText: locale.queueVisit.aa,
        callback: (res) => {
          if (res) {
            setRefresh(true);

            const message = {
              type: 'wss',
              path: 'visit-status',
              action: 'update',
              data: {
                user: visit.user,
                status: data,
                log: visit.log,
                locations: configuration.locations
              }
            };
            wsCall(message);
          }
        }
      });
    } else if (data === 'transfer' && configuration.locations.length > 2) {

      Dialog({view: 'queueVisitTransfer'});

    } else {
      setRefresh(true);

      const message = {
        type: 'wss',
        path: 'visit-status',
        action: 'update',
        data: {
          user: visit.user,
          status: data,
          log: visit.log
        }
      };
      wsCall(message);
    }
  }

  const Buttons = () => {
    if (navigation.tab === 'wait' && (configuration.locations.length <= 1 || (configuration.locations.length > 1 && !configuration.queue.transfer))) {
      return [{text: locale.queueVisit.m, cssClass: 'cs-queue-visit-send', handler: 'close'}, {text: locale.queueVisit.n, cssClass: 'cs-queue-visit-send', handler: () => Status('in')}];
    } else if (navigation.tab === 'wait' && configuration.locations.length > 1 && configuration.queue.transfer) {
      return [{text: locale.queueVisit.m, cssClass: 'cs-queue-visit-send', handler: 'close'}, {text: locale.queueVisit.r, cssClass: 'cs-queue-visit-send', handler: () => Status('transfer')}, {text: locale.queueVisit.n, cssClass: 'cs-queue-visit-send', handler: () => Status('in')}];
    } else if (navigation.tab === 'in' && configuration.locations.length > 1) {
      return [{text: locale.queueVisit.m, cssClass: 'cs-queue-visit-send', handler: 'close'}, {text: locale.queueVisit.d, cssClass: 'cs-queue-visit-send', handler: () => Status('discharge')}, {text: locale.queueVisit.r, cssClass: 'cs-queue-visit-send', handler: () => Status('transfer')}, {text: locale.queueVisit.c, cssClass: 'cs-queue-visit-send', handler: () => Status('wait')}];
    } else if (navigation.tab === 'in' && configuration.locations.length <= 1) {
      return [{text: locale.queueVisit.m, cssClass: 'cs-queue-visit-send', handler: 'close'}, {text: locale.queueVisit.d, cssClass: 'cs-queue-visit-send', handler: () => Status('discharge')}, {text: locale.queueVisit.c, cssClass: 'cs-queue-visit-send', handler: () => Status('wait')}];
    } else if (navigation.tab === 'transfer') {
      return [{text: locale.queueVisit.m, cssClass: 'cs-queue-visit-send', handler: 'close'}, {text: locale.queueVisit.n, cssClass: 'cs-queue-visit-send', handler: () => Status('in')}, {text: locale.queueVisit.c, cssClass: 'cs-queue-visit-send', handler: () => Status('wait')}];
    }
  }

  const LogItem = data => {
    const itemDate = dayjs(parseInt(data.item.timestamp)).format("MMMM D, YYYY @ h:mm A");

    return <li key={data.item.timestamp}>
      <div className="mbsc-row cs-queue-visit-list">
        {data.item.type === 'wait' &&
            <FontAwesomeIcon className="cs-queue-visit-list-icon-mobile" icon={faBracketsSquare} />
        }
        {data.item.type === 'in' &&
            <FontAwesomeIcon className="cs-queue-visit-list-icon-mobile" icon={faRightToBracket} />
        }
        {data.item.type === 'discharge' &&
            <FontAwesomeIcon className="cs-queue-visit-list-icon-mobile" icon={faLeftFromBracket} />
        }
        {data.item.type === 'transfer' &&
            <FontAwesomeIcon className="cs-queue-visit-list-icon-mobile" icon={faDownFromBracket} />
        }
        <div className="cs-queue-visit-list-section">
          <div className="mbsc-bold mbsc-txt-muted">{data.item.user.id} | {data.item.user.firstName && data.item.user.firstName} {data.item.user.middleName && data.item.user.middleName} {data.item.user.lastName && data.item.user.lastName} {data.item.user.paternalName && data.item.user.paternalName} {data.item.user.maternalName && data.item.user.maternalName}</div>
          <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-queue-visit-item-txt">
            {itemDate}
          </div>
        </div>
      </div>
    </li>;
  }

  const Update = useCallback(() => {
    setRefresh(true);

    const message = {
      type: 'wss',
      path: 'visit-patient',
      action: 'update',
      data: {
        firstName: firstName ? firstName : null,
        middleName: middleName ? middleName : null,
        lastName: lastName ? lastName : null,
        paternalName: paternalName ? paternalName : null,
        maternalName: maternalName ? maternalName : null,
        dob: dob ? dob : null,
        gender: gender ? gender : null,
        reason: reason ? reason : null,
        notes: notes ? notes : null,
        user: visit.user
      }
    };
    wsCall(message);
  }, [wsCall, firstName, lastName, paternalName, maternalName, dob, visit.user, middleName, gender, reason, notes])

  const UpdateVisitStatus = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queueVisit.u, color: 'info', duration: 1000, display: 'bottom'});
    }
    CloseDialog();
    setRefresh(false);
  }, [locale.queueVisit.u, CloseDialog, dialog.view]);

  const DeleteVisit = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queueVisit.ab, color: 'info', duration: 1000, display: 'bottom'});
    }
    CloseDialog();
    setRefresh(false);
  }, [locale.queueVisit.ab, CloseDialog, dialog.view]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'visit') {
      DeleteVisit(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, DeleteVisit, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'visit-status') {
      UpdateVisitStatus(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdateVisitStatus, setWsResponse]);

  useEffect(() => {
    if (toSave) {
      Update();
      setToSave(false);
    }
  }, [toSave, Update]);

  return (
      <Popup className="cs-queue-visit-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} headerText={User(visit)} buttons={Buttons()} isOpen={dialog.view === 'queueVisit'} onClose={CloseDialog}>
        <TabNav theme="ios" themeVariant="light" display='inline' >
          <NavItem selected={dialog.tab === 'visit'} onClick={(ev) => Dialog({tab: 'visit'})} >
            <span className={dialog.tab === 'visit' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.o}</span>
          </NavItem>
          {configuration.options.ai && configuration.queue.triage && visit.triage.length === 0 && !visit.soap.instructions &&
              <NavItem selected={dialog.tab === 'triage'} onClick={(ev) => {Triage(); Dialog({tab: 'triage'});}} >
                <span className={dialog.tab === 'triage' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.ai}</span>
              </NavItem>
          }
          {visit.soap.instructions &&
              <NavItem selected={dialog.tab === 'instructions'} onClick={(ev) => {Instructions(); Dialog({tab: 'instructions'});}} >
                <span className={dialog.tab === 'instructions' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.ak}</span>
              </NavItem>
          }
          <NavItem selected={dialog.tab === 'details'} onClick={(ev) => Dialog({tab: 'details'})} >
            <span className={dialog.tab === 'details' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.s}</span>
          </NavItem>
          <NavItem selected={dialog.tab === 'log'} onClick={(ev) => Dialog({tab: 'log'})} >
            <span className={dialog.tab === 'log' ? 'mbsc-bold cs-queue-visit-nav' : 'mbsc-bold'} >{locale.queueVisit.t}</span>
          </NavItem>
        </TabNav>
        {dialog.tab === 'visit' &&
          <>
            {configuration.patients.longName ?
                <>
                  <Input inputStyle="underline" label={locale.queueVisit.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} onBlur={Update} />
                  <Input inputStyle="underline" label={locale.queueVisit.l} labelStyle="floating" type="text" name="middleName" value={middleName} onChange={ev => setMiddleName(ev.target.value)} onBlur={Update} />
                  <Input inputStyle="underline" label={locale.queueVisit.i} labelStyle="floating" type="text" name="paternalName" value={paternalName} onChange={ev => setPaternalName(ev.target.value)} onBlur={Update} />
                  <Input inputStyle="underline" label={locale.queueVisit.h} labelStyle="floating" type="text" name="maternalName" value={maternalName} onChange={ev => setMaternalName(ev.target.value)} onBlur={Update} />
                </>
                :
                <>
                  <Input inputStyle="underline" label={locale.queueVisit.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} onBlur={Update} />
                  <Input inputStyle="underline" label={locale.queueVisit.k} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} onBlur={Update} />
                </>
            }
            <Datepicker
                controls={['date']}
                touchUi={width > 576 ? false : true}
                inputStyle="underline"
                label={locale.queueVisit.f}
                labelStyle="floating"
                value={dob}
                selectMultiple={false}
                max={dayjs().format("YYYY-M-D")}
                onChange={(event) => {setDob(event.value); setToSave(true);}}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.queueVisit.p}
                labelStyle="floating"
                touchUi={false}
                rows={locale.queueVisit.q.length}
                display="anchored"
                data={locale.queueVisit.q}
                value={gender}
                onChange={(event) => {setGender(event.value); setToSave(true);}}
            />
            <div className="cs-queue-visit-textarea">
              <Textarea inputStyle="underline" labelStyle="floating" label={locale.queueVisit.e} value={reason} onChange={(ev) => setReason(ev.target.value)} onBlur={Update} />
            </div>
          </>
        }
        {dialog.tab === 'details' &&
          <>
            <div className="cs-queue-visit-helper">
              <p>{locale.queueVisit.w}</p>
            </div>
            <div className="cs-queue-visit-textarea">
              <Textarea inputStyle="underline" labelStyle="floating" label={locale.queueVisit.v} value={notes} onChange={(ev) => setNotes(ev.target.value)} onBlur={Update} />
            </div>
            {(configuration.queue.delete || user.options.admin || user.options.owner) &&
                <div className="mbsc-row cs-queue-visit-delete-block">
                  <div className="mbsc-col mbsc-align-right">
                    <Button className="cs-queue-visit-delete-button mbsc-bold" onClick={() => Delete()} variant="flat" >
                      <FontAwesomeIcon className="cs-queue-visit-delete-icon" icon={faTrashCan} /> {locale.queueVisit.x}
                    </Button>
                  </div>
                </div>
            }
          </>
        }
        {dialog.tab === 'log' &&
            <>
              <Listview
                  theme="ios"
                  themeVariant="light"
                  select="single"
                  itemType={LogItem}
                  data={visit.log}
              />
            </>
        }
        {(dialog.tab === 'triage' || dialog.tab === 'instructions') && Empty(qr) &&
            <div className="cs-queue-visit-loading mbsc-align-center" >
              <Loading />
            </div>
        }
        {(dialog.tab === 'triage' || dialog.tab === 'instructions') && !Empty(qr) &&
            <div className="mbsc-row mbsc-justify-content-center cs-queue-visit-qr">
              <QRCode
                  size={256}
                  value={qr.url}
                  viewBox={`0 0 256 256`}
              />
            </div>
        }
      </Popup>
  );
}

export default QueueVisit;
