import React, {useCallback, useContext, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/patientVitalsRange.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function PatientVitalsRange({locale, dialog, Dialog, setRange, metric, patient, date, setLoading}) {
  const {wsCall} = useContext(NetworkContext);
  const menu = useRef(locale.patientVitalsRange.a);

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Select = data => {
    setRange(data);
    setLoading(true);

    const message = {
      type: 'wss',
      path: 'vitals-metric',
      action: 'get',
      data: {
        patient: patient,
        metric: metric,
        range: data,
        date: date
      }
    };
    wsCall(message);
    CloseDialog();
  }

  const MenuItem = data => {
    let item = '';

    if (data.item.week) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-range-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-range-button mbsc-bold" onClick={() => Select('week')} >
          {locale.patientVitalsRange.b}
        </Button>
      </li>;
    } else if (data.item.month) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-range-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-range-button mbsc-bold" onClick={() => Select('month')} >
          {locale.patientVitalsRange.c}
        </Button>
      </li>;
    } else if (data.item.day) {
      item = <li key={data.item.timestamp} className="cs-patient-vitals-range-li">
        <Button color="primary" variant="flat" className="cs-patient-vitals-range-button mbsc-bold" onClick={() => Select('day')} >
          {locale.patientVitalsRange.d}
        </Button>
      </li>;
    }

    return item;
  }

  return (
      <Popup className="cs-patient-vitals-range-popup" anchor={document.getElementById('patientVitalsRange')} display="anchored" buttons={[]} isOpen={dialog.view === 'patientVitalsRange'} onClose={CloseDialog}>
        <Listview
            theme="ios"
            themeVariant="light"
            select="single"
            itemType={MenuItem}
            data={menu.current}
        />
      </Popup>
  );
}

export default PatientVitalsRange;
