import React, {useCallback, useState, useEffect, useContext} from 'react';
import {Popup, toast, Input, Textarea, Datepicker, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/queueNewVisit.css';
import Empty from "../utils/Empty";
import User from "../utils/User";
import dayjs from 'dayjs';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function QueueNewVisit({locale, configuration, dialog, Dialog, type, width, visit, location, setRefresh}) {
  const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
  const [firstName, setFirstName] = useState(visit.firstName ? visit.firstName : '');
  const [middleName, setMiddleName] = useState(visit.middleName ? visit.middleName : '');
  const [lastName, setLastName] = useState(visit.lastName ? visit.lastName : '');
  const [paternalName, setPaternalName] = useState(visit.paternalName ? visit.paternalName : '');
  const [maternalName, setMaternalName] = useState(visit.maternalName ? visit.maternalName : '');
  const [dob, setDob] = useState(visit.dob ? visit.dob : '');
  const [gender, setGender] = useState(visit.gender ? visit.gender : 'male');
  const [reason, setReason] = useState('');
  const [toSave, setToSave] = useState(false);

  const CloseDialog = useCallback(() => {
    Dialog({view: 'close'});
  }, [Dialog]);

  const Checkin = data => {
    if (configuration.patients.longName && !firstName && !paternalName) {
      toast({message: locale.queueNewVisit.a, color: 'danger', duration: 3000, display: 'bottom'});
    } else if (!configuration.patients.longName && !firstName && !lastName) {
      toast({message: locale.queueNewVisit.b, color: 'danger', duration: 3000, display: 'bottom'});
    } else {
      setRefresh(true);

      const message = {
        type: 'wss',
        path: 'visit',
        action: 'put',
        data: {
          firstName: firstName ? firstName : null,
          lastName: lastName ? lastName : null,
          paternalName: paternalName ? paternalName : null,
          maternalName: maternalName ? maternalName : null,
          dob: dob ? dob : null,
          gender: gender ? gender : null,
          user: visit.user,
          reason: reason ? reason : null,
          type: type ? type : null,
          location: location,
          new: visit.new ? visit.new : null,
          status: data
        }
      };
      wsCall(message);
    }
  }

  const Update = useCallback(() => {

    const message = {
      type: 'wss',
      path: 'patient',
      action: 'update',
      data: {
        firstName: firstName ? firstName : null,
        middleName: middleName ? middleName : null,
        lastName: lastName ? lastName : null,
        paternalName: paternalName ? paternalName : null,
        maternalName: maternalName ? maternalName : null,
        dob: dob ? dob : null,
        gender: gender ? gender : null,
        patient: visit.user
      }
    };
    wsCall(message);
  }, [wsCall, firstName, lastName, paternalName, maternalName, dob, visit.user, middleName, gender])

  const UpdatePatient = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queueNewVisit.o, color: 'info', duration: 1000, display: 'bottom'});
    }
  }, [locale.queueNewVisit.o]);

  const PutVisit = useCallback(data => {
    if (data.toast) {
      toast({message: locale.queueNewVisit.s, color: 'info', duration: 1000, display: 'bottom'});
      CloseDialog();
      setRefresh(false);
    }
  }, [locale.queueNewVisit.s, CloseDialog]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'visit') {
      PutVisit(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, PutVisit, setWsResponse]);

  useEffect(() => {
    if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'patient') {
      UpdatePatient(wsResponse.data);
      setWsResponse({});
    }
  }, [wsResponse, UpdatePatient, setWsResponse]);

  useEffect(() => {
    if (toSave) {
      Update();
      setToSave(false);
    }
  }, [toSave, Update]);

  return (
      <Popup className="cs-queue-new-visit-popup" width={600} display="center" closeOnOverlayClick={false} closeOnEsc={false} scrollLock={false} headerText={User(visit)} buttons={[{text: locale.queueNewVisit.d, cssClass: 'cs-queue-new-visit-send', handler: () => Dialog({view: 'queueNewCheck'})}, {text: locale.queueNewVisit.r, cssClass: 'cs-queue-new-visit-send', handler: () => Checkin('in')}, {text: locale.queueNewVisit.c, cssClass: 'cs-queue-new-visit-send', handler: () => Checkin('wait')}]} isOpen={dialog.view === 'queueNewVisit'} onClose={CloseDialog}>
        {visit.new ?
            <div className="cs-queue-new-visit-helper">
              <p>{locale.queueNewVisit.m}</p>
            </div>
            :
            <div className="cs-queue-new-visit-helper">
              <p>{locale.queueNewVisit.n}</p>
            </div>
        }
        {configuration.patients.longName ?
            <>
              <Input inputStyle="underline" label={locale.queueNewVisit.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} onBlur={!visit.new ? Update : null} />
              <Input inputStyle="underline" label={locale.queueNewVisit.l} labelStyle="floating" type="text" name="middleName" value={middleName} onChange={ev => setMiddleName(ev.target.value)} onBlur={!visit.new ? Update : null} />
              <Input inputStyle="underline" label={locale.queueNewVisit.i} labelStyle="floating" type="text" name="paternalName" value={paternalName} onChange={ev => setPaternalName(ev.target.value)} onBlur={!visit.new ? Update : null} />
              <Input inputStyle="underline" label={locale.queueNewVisit.h} labelStyle="floating" type="text" name="maternalName" value={maternalName} onChange={ev => setMaternalName(ev.target.value)} onBlur={!visit.new ? Update : null} />
            </>
            :
            <>
              <Input inputStyle="underline" label={locale.queueNewVisit.j} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} onBlur={!visit.new ? Update : null} />
              <Input inputStyle="underline" label={locale.queueNewVisit.k} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} onBlur={!visit.new ? Update : null} />
            </>
        }
        <Datepicker
            controls={['date']}
            touchUi={width > 576 ? false : true}
            inputStyle="underline"
            label={locale.queueNewVisit.f}
            labelStyle="floating"
            value={dob}
            selectMultiple={false}
            max={dayjs().format("YYYY-MM-DD")}
            onChange={(event) => {setDob(event.value); if (!visit.new) {setToSave(true);}}}
        />
        <Select
            animation="slide-down"
            inputStyle="underline"
            label={locale.queueNewVisit.p}
            labelStyle="floating"
            touchUi={false}
            rows={locale.queueNewVisit.q.length}
            display="anchored"
            data={locale.queueNewVisit.q}
            value={gender}
            onChange={(event) => {setGender(event.value); if (!visit.new) {setToSave(true);}}}
        />
        <div className="cs-queue-new-visit-textarea">
          <Textarea inputStyle="underline" labelStyle="floating" label={locale.queueNewVisit.e} value={reason} onChange={(ev) => setReason(ev.target.value)} />
        </div>
      </Popup>
  );
}

export default QueueNewVisit;
