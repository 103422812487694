import React, {useCallback, useState, useEffect, useContext} from 'react';
import {SegmentedGroup, Segmented, toast, Select, Input, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/login.css';
import {faWatchFitness} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Empty from './utils/Empty';
import Mobile from './utils/Mobile';
import LoginHeader from './blocks/LoginHeader';
import LoginHelp from "./dialogs/LoginHelp";
import LoginReset from "./dialogs/LoginReset";
import Spanish from "./locale/spanish.json";
import English from "./locale/english.json";
import {NetworkContext} from './utils/NetworkContext';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

const LoginType = () => {
    const item = window.localStorage.login;
    if (!item) {
        window.localStorage.login = 'email'
        return 'email';
    } else {
        return item;
    }
}

const Country = () => {
    const item = window.localStorage.country;
    if (!item) {
        window.localStorage.country = 'us';
        return 'us';
    } else {
        return item;
    }
}

function Login({wss, token, account, locale, Navigation, dialog, Dialog, configuration, reset, setConfiguration, setLocale, select, setDialog, setNavigation, shortener, setMicroApp}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [country, setCountry] = useState(Country());
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [next, setNext] = useState(true);
    const [type, setType] = useState(LoginType());

    const Configuration = data => {
        const message = {
            type: 'rest',
            path: 'account-configuration',
            action: 'get',
            data: {
                account: data
            }
        };
        restCall(message);
    }

    const Shortener = data => {
        const message = {
            type: 'rest',
            path: 'shortener',
            action: 'get',
            data: {
                shortener: shortener.current
            }
        };
        restCall(message);
    }

    const Auth = () => {
        if (!password) {
            toast({message: locale.login.h, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const itemData = {
                account: account.current,
                password: password
            }
            if (type === 'email') {
                itemData.id = email;
                itemData.type = 'email';
            } else if (type === 'mobile') {
                itemData.id = mobile.replace(/[()\s-]/g, '');
                itemData.country = country;
                itemData.type = 'mobile';
            } else if (type === 'username') {
                itemData.id = username;
                itemData.type = 'username';
            }

            const message = {
                type: 'rest',
                path: 'token',
                action: 'get',
                data: itemData
            };
            restCall(message);
        }
    }

    const Forgot = () => {
        if (type === 'email' && !email) {
            toast({message: locale.login.f, color: 'danger', display: 'bottom', duration: 3000});
        } else if (type === 'mobile' && !mobile) {
            toast({message: locale.login.q, color: 'danger', display: 'bottom', duration: 3000});
        } else if (type === 'username' && !username) {
            toast({message: locale.login.r, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const itemData = {
                account: account.current
            }
            if (type === 'email') {
                itemData.id = email;
                itemData.type = 'email';
            } else if (type === 'mobile') {
                itemData.id = mobile.replace(/[()\s-]/g, '');
                itemData.country = country;
                itemData.type = 'mobile';
            } else if (type === 'username') {
                itemData.id = username;
                itemData.type = 'username';
            }

            const message = {
                type: 'rest',
                path: 'user-reset',
                action: 'update',
                data: itemData
            };
            restCall(message);
        }
    }

    const Next = () => {
        if (type === 'email' && !email) {
            toast({message: locale.login.f, color: 'danger', display: 'bottom', duration: 3000});
        } else if (type === 'mobile' && !mobile) {
            toast({message: locale.login.q, color: 'danger', display: 'bottom', duration: 3000});
        } else if (type === 'username' && !username) {
            toast({message: locale.login.r, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const itemData = {
                account: account.current
            }
            if (type === 'email') {
                itemData.id = email;
                itemData.type = 'email';
            } else if (type === 'mobile') {
                itemData.id = mobile.replace(/[()\s-]/g, '');
                itemData.country = country;
                itemData.type = 'mobile';
            } else if (type === 'username') {
                itemData.id = username;
                itemData.type = 'username';
            }

            const message = {
                type: 'rest',
                path: 'user',
                action: 'get',
                data: itemData
            };
            restCall(message);
        }
    }

    const Help = () => {
        Dialog({view: 'loginHelp'});
    }

    const Type = data => {
        setType(data.target.value);
        window.localStorage.login = data.target.value;
    }

    const Code = data => {
        window.localStorage.country = data;
        setCountry(data);
    }

    const SmartDocFitness = () => {
        window.location.href = 'https://smartdocfitness.com';
    }

    const Reset = useCallback(() => {
        toast({message: locale.login.x, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'rest',
            path: 'token-user',
            action: 'get',
            data: {
                token: reset.current
            }
        };
        restCall(message);
    }, [restCall, locale.login.x]);

    const UpdateUserReset = useCallback(data => {
        if (data.error) {
            toast({message: locale.login.k, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.toast) {
            toast({message: locale.login.l, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.login.k, locale.login.l]);

    const GetToken = useCallback(data => {
        if (data.token) {
            window.localStorage.token = data.token;
            token.current = data.token;
        }
        if (data.url) {
            wss.current = data.url;
            Navigation({view: 'dashboard'});
        }
        if (data.error && data.user) {
            toast({message: locale.login.k, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.error && data.password) {
            toast({message: locale.login.n, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.error && data.reset) {
            toast({message: locale.login.i, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.toast) {
            toast({message: locale.login.o, color: 'info', display: 'bottom', duration: 1000});
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
    }, [locale.login.k, locale.login.n, locale.login.i, locale.login.o, Navigation, Dialog, dialog.view]);

    const GetUser = useCallback(data => {
        if (data.toast) {
            setNext(false);
        }
        if (data.error && data.user) {
            toast({message: locale.login.k, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.login.k]);

    const GetTokenUser = useCallback(data => {
        if (data.type === 'email') {
            setEmail(data.user);
        }
        if (data.type === 'mobile') {
            setMobile(data.user);
        }
        if (data.type === 'username') {
            setUsername(data.user);
        }
        if (data.user) {
            Dialog({view:'passwordReset'});
        }
        if (data.error) {
            toast({message: locale.login.y, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.login.y, Dialog]);

    const GetAccountConfiguration = useCallback(data => {
        if (data.configuration) {
            let item = {...data.configuration};
            item.locale.es = Object.assign(item.locale.es, Spanish);
            item.locale.en = Object.assign(item.locale.en, English);
            setConfiguration(item);
            if (window.localStorage.locale && window.localStorage.locale === 'en') {
                setLocale(item.locale.en);
            } else if (window.localStorage.locale && window.localStorage.locale === 'es') {
                setLocale(item.locale.es);
            }
            select.current = false;
        }
        if (data.account) {
            account.current = data.account;
            window.localStorage.account = data.account;
        }
        if (data.url) {
            wss.current = data.url;
        }
        if (data.dialog) {
            setDialog(data.dialog);
        }
        if (data.navigation) {
            setNavigation(data.navigation);
        }
        if (data.error) {
            toast({message: locale.login.z, color: 'danger', duration: 3000, display: 'bottom'});
        }
        if (reset.current) {
            Reset();
        }
    }, [locale.login.z, Reset]);

    const GetShortener = useCallback(data => {
        if (data.microApp) {
            setNavigation(data.microApp.navigation);
            delete data.microApp.navigation;
            setDialog(data.microApp.dialog);
            delete data.microApp.dialog;
            let item = {...data.microApp};
            item.locale.es = Object.assign(item.locale.es, Spanish);
            item.locale.en = Object.assign(item.locale.en, English);
            setMicroApp(item);
            if (window.localStorage.locale && window.localStorage.locale === 'en') {
                setLocale(item.locale.en);
            } else if (window.localStorage.locale && window.localStorage.locale === 'es') {
                setLocale(item.locale.es);
            }
        }
        if (data.error) {
            toast({message: locale.login.w, color: 'danger', duration: 3000, display: 'bottom'});
        }
    }, [locale.login.w]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'user-reset') {
            UpdateUserReset(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateUserReset, setRestResponse])

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'token') {
            GetToken(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetToken, setRestResponse])

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'user') {
            GetUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetUser, setRestResponse])

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'token-user') {
            GetTokenUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetTokenUser, setRestResponse])

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'account-configuration') {
            GetAccountConfiguration(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetAccountConfiguration, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'shortener') {
            GetShortener(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetShortener, setRestResponse]);

    useEffect(() => {
        if (shortener.current) {
            Shortener();
        } else if (!account.current && !shortener.current) {
            Dialog({view: 'headerAccount'});
        } else if (account.current && !shortener.current) {
            Configuration(account.current);
        }
    }, []);

    return (
        <>
            <LoginHeader
                Dialog={Dialog}
                dialog={dialog}
                configuration={configuration}
                locale={locale}
                select={select}
                setLocale={setLocale}
            />
            <div className="mbsc-grid cs-login-main">
                {!Empty(configuration) &&
                    <div className="mbsc-row mbsc-justify-content-center">
                        <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-5 cs-login-card">
                            <div style={{backgroundImage: `url(${configuration.logo})`}} className="cs-login-card-logo" />
                            <div className={(!next && type === 'mobile') ? 'cs-login-header-mobile' : ''}>
                                {next &&
                                    <h2 className="cs-login-card-title mbsc-txt-muted">
                                        {locale.login.c}
                                    </h2>
                                }
                                {!next && type === 'mobile' &&
                                    <h2 className="cs-login-card-title mbsc-txt-muted">
                                        {mobile}
                                    </h2>
                                }
                                {!next && type === 'email' &&
                                    <h2 className="cs-login-card-title mbsc-txt-muted">
                                        {email}
                                    </h2>
                                }
                                {!next && type === 'username' &&
                                    <h2 className="cs-login-card-title mbsc-txt-muted">
                                        {username}
                                    </h2>
                                }
                            </div>
                            <div className="mbsc-row">
                                <SegmentedGroup className="cs-login-nav" name="login" color="primary" value={type} onChange={Type}>
                                    <Segmented value="email" >
                                        {locale.login.a}
                                    </Segmented>
                                    <Segmented value="mobile" >
                                        {locale.login.u}
                                    </Segmented>
                                    <Segmented value="username" >
                                        {locale.login.v}
                                    </Segmented>
                                </SegmentedGroup>
                            </div>
                            {next && type === 'mobile' &&
                                <div className="mbsc-row">
                                    <div className="mbsc-col-4 cs-login-inputs-left">
                                        <Select
                                            animation="slide-down"
                                            dropdown={false}
                                            inputStyle="outline"
                                            label={locale.login.t}
                                            labelStyle="floating"
                                            rows={locale.login.s.length}
                                            display="anchored"
                                            touchUi={false}
                                            value={country}
                                            data={locale.login.s}
                                            disabled={mobile.length !== 0}
                                            onChange={(event) => Code(event.value)}
                                        />
                                    </div>
                                    <div className="mbsc-col-8 cs-login-inputs-right">
                                        <Input className="cs-login-inputs-right-input" label={locale.login.u} inputStyle="outline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => Mobile({data: ev.target.value, setMobile: setMobile, country: country})} />
                                    </div>
                                </div>
                            }
                            {next && type === 'email' &&
                                <Input inputStyle="outline" label={locale.login.a} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
                            }
                            {next && type === 'username' &&
                                <Input inputStyle="outline" label={locale.login.v} labelStyle="floating" type="text" name="Username" value={username} onChange={ev => setUsername(ev.target.value)} />
                            }
                            {!next &&
                                <Input inputStyle="outline" label={locale.login.b} labelStyle="floating" minLength="8" type="password" name="Password" passwordToggle={true} value={password} onChange={ev => setPassword(ev.target.value)} />
                            }
                            {next &&
                                <div className="mbsc-row mbsc-justify-content-end">
                                    <div className="mbsc-col-4 mbsc-button-group-block">
                                        <Button color="primary" variant="flat" className="cs-login-button mbsc-bold" onClick={() => Next()} >
                                            {locale.login.p}
                                        </Button>
                                    </div>
                                </div>
                            }
                            {!next &&
                                <div className="mbsc-row mbsc-justify-content-end">
                                    <div className="mbsc-col-4 mbsc-button-group-block">
                                        <Button color="primary" variant="flat" className="cs-login-button mbsc-bold" onClick={() => Auth()} >
                                            {locale.login.c}
                                        </Button>
                                    </div>
                                </div>
                            }
                            <div className="mbsc-row cs-login-footer-section">
                                <div className="mbsc-col cs-login-footer">
                                    <Button className="cs-login-button-lang mbsc-bold" onClick={() => Help()} variant="flat" >
                                        {locale.login.g}
                                    </Button>
                                </div>
                                <div className="mbsc-col cs-login-footer mbsc-align-right">
                                    <Button className="cs-login-button-lang mbsc-bold" onClick={() => SmartDocFitness()} variant="flat" >
                                        <FontAwesomeIcon className="cs-login-icon-secondary" icon={faWatchFitness} /> {locale.login.m}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {dialog.view === 'loginHelp' &&
                    <LoginHelp
                        locale={locale}
                        dialog={dialog}
                        Dialog={Dialog}
                        account={account.current}
                    />
                }
                {dialog.view === 'loginReset' &&
                    <LoginReset
                        locale={locale}
                        dialog={dialog}
                        Dialog={Dialog}
                        reset={reset}
                        account={account.current}
                        email={email}
                        mobile={mobile}
                        username={username}
                        setEmail={setEmail}
                        setMobile={setMobile}
                        setUsername={setUsername}
                        type={type}
                    />
                }
            </div>
        </>
    );
}

export default Login;
