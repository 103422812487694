import React from "react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/navigationItem.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/pro-duotone-svg-icons";

function Item(data, navigation) {
  return <li data-selected={data.item.name === navigation.tab}>
    {data.item.name === 'back' && <FontAwesomeIcon icon={faArrowLeft} />} <span className="mbsc-bold mbsc-txt-muted">{data.item.label}</span>
  </li>;
}

export default Item;
