function Menu(data, Navigation, navigation) {
  let item = '';
  if (typeof data === 'number' && !isNaN(data)) {
    item = navigation.menu[data].name;
  } else if (typeof data === 'object' && data !== null) {
    item = data.target.value;
  } else if (typeof data === 'string' && data !== null) {
    item = data;
  }

  if (item === 'back') {
    Navigation({back: true});
  } else {
    Navigation({tab: item});
  }
  window.scrollTo(0, 0);
}

export default Menu;
