import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Input, toast, Datepicker, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/patientImmunization.css';
import dayjs from "dayjs";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientImmunization({locale, dialog, Dialog, setPatient, setImmunizations, immunizations, patient, width, immunization}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [date, setDate] = useState(immunization.date ? immunization.date : '');
    const [text, setText] = useState(immunization.text ? immunization.text : '');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Add = () => {
        if (!text) {
            toast({message: locale.patientImmunization.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-immunization',
                action: 'put',
                data: {
                    patient: patient.id,
                    immunizations: immunizations,
                    date: date,
                    text: text
                }
            };
            wsCall(message);
        }
    }

    const Header = () => {
        if (!Empty(immunization) && immunization.id) {
            return locale.patientImmunization.d;
        } else {
            return locale.patientImmunization.b;
        }
    }

    const Update = () => {
        if (!text) {
            toast({message: locale.patientImmunization.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-immunization',
                action: 'update',
                data: {
                    patient: patient.id,
                    immunizations: immunizations,
                    date: date,
                    text: text,
                    id: immunization.id
                }
            };
            wsCall(message);
        }
    }

    const Buttons = () => {
        if (!Empty(immunization) && immunization.id) {
            return [{text: locale.patientImmunization.g, cssClass: 'cs-patient-medication-popup-save', handler: 'close'}, {text: locale.patientImmunization.h, cssClass: 'cs-patient-medication-popup-save', handler: () => Update()}];
        } else {
            return [{text: locale.patientImmunization.g, cssClass: 'cs-patient-immunization-popup-save', handler: 'close'}, {text: locale.patientImmunization.a, cssClass: 'cs-patient-immunization-popup-save', handler: () => Add()}];
        }
    }

    const PutPatientImmunization = useCallback(data => {
        if (data.immunizations) {
            const item = {...patient};
            item.immunizations = data.immunizations;
            setPatient(item);
            setImmunizations(data.immunizations);
            setText('');
            CloseDialog();
        }
    }, [patient, CloseDialog]);

    const UpdatePatientImmunization = useCallback(data => {
        if (data.immunizations) {
            const item = {...patient};
            item.immunizations = data.immunizations;
            setPatient(item);
            setImmunizations(data.immunizations);
            setText('');
            CloseDialog();
        }
    }, [patient, CloseDialog]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'patient-immunization') {
            UpdatePatientImmunization(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdatePatientImmunization, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'patient-immunization') {
            PutPatientImmunization(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutPatientImmunization, setWsResponse]);

    return (
        <Popup className="cs-patient-immunization-popup" display="center" width={600} closeOnOverlayClick={false} closeOnEsc={false} headerText={Header()} buttons={Buttons()} isOpen={dialog.view === 'patientImmunization'} onClose={CloseDialog}>
            <div className="mbsc-grid cs-patient-immunization-grid">
                <div className="mbsc-row">
                    <div className="mbsc-col-4 cs-patient-immunization-inputs-left">
                        <Datepicker
                            controls={['date']}
                            touchUi={width <= 576}
                            inputStyle="underline"
                            label={locale.patientImmunization.c}
                            labelStyle="floating"
                            value={date}
                            selectMultiple={false}
                            max={dayjs().format("YYYY-MM-DD")}
                            onChange={(event) => setDate(event.value)}
                        />
                    </div>
                    <div className="mbsc-col-8 cs-patient-immunization-inputs-right">
                        <Input className="cs-patient-immunization-inputs-right-input" label={locale.patientImmunization.e} inputStyle="underline" labelStyle="floating" type="text" name="allergy" value={text} onChange={ev => setText(ev.target.value)} />
                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default PatientImmunization;
