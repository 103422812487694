import React, {useCallback, useState, useEffect, useContext} from 'react';
import {toast, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/microAppPatientVisitInstructions.css';
import Loading from "../utils/Loading";
import Empty from "../utils/Empty";
import dayjs from "dayjs";
import 'dayjs/locale/es';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import BalloonBlock from "@ckeditor/ckeditor5-build-balloon-block";
import {NetworkContext} from '../utils/NetworkContext';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientVisitInstructions({locale, microApp, width}) {
    dayjs.locale(window.localStorage.locale);
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [instructions, setInstructions] = useState('');
    const [loading, setLoading] = useState(true);

    const Instructions = () => {

        const message = {
            type: 'rest',
            path: 'visit-instructions',
            action: 'get',
            data: {
                user: microApp.user,
                arrival: microApp.arrival,
                microApp: {account: microApp.account}
            }
        };
        restCall(message);
    }

    const GetVisitInstructions = useCallback(data => {
        if (data.instructions) {
            setInstructions(data.instructions);
        }
        if (data.error && data.queue) {
            toast({message: locale.microAppPatientVisitInstructions.a, color: 'danger', display: 'top', duration: 3000});
        }
        setLoading(false);
    }, [locale.microAppPatientVisitInstructions.a]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'visit-instructions') {
            GetVisitInstructions(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetVisitInstructions, setRestResponse]);

    useEffect(() => {
        Instructions();
    }, []);

    return (
        <div className="mbsc-grid cs-microapp-patient-visit-instructions-main">
            <div className="mbsc-row mbsc-justify-content-center">
                <div className="mbsc-col-sm-12 mbsc-col-md-11 mbsc-col-xl-10 cs-microapp-patient-visit-instructions-page">
                    <div className="mbsc-row cs-microapp-patient-visit-instructions-section cs-microapp-patient-visit-instructions-title">
                        <div className="mbsc-col" >
                            <div className="cs-microapp-patient-visit-instructions-title-text">
                                <p className="mbsc-txt-muted mbsc-bold">{locale.microAppPatientVisitInstructions.b}</p>
                            </div>
                        </div>
                        <div className="mbsc-col-auto" >
                            <Button color="primary" variant="flat" className="cs-microapp-patient-visit-instructions-button mbsc-bold mbsc-txt-muted" >
                                {width < 768 && window.localStorage.locale === 'en' && dayjs(parseInt(microApp.arrival)).format("M/D/YYYY")}
                                {width < 768 && window.localStorage.locale === 'es' && dayjs(parseInt(microApp.arrival)).format("D/M/YYYY")}
                                {width > 768 && dayjs(parseInt(microApp.arrival)).format("MMMM D, YYYY @ h:mm A")}
                            </Button>
                        </div>
                    </div>
                    {loading &&
                        <div className="cs-microapp-patient-visit-instructions-loading mbsc-align-center" >
                            <Loading />
                        </div>
                    }
                    {!loading &&
                        <CKEditor
                            id="editor-instructions"
                            onReady={editor => {editor.enableReadOnlyMode('editor-instructions')}}
                            editor={BalloonBlock}
                            data={instructions}
                            disableWatchdog={true}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default PatientVisitInstructions;
