function User(data) {
  if (data.type === 'mobile' || data.type === 'whatsapp') {
    if (data.user.startsWith('+1')) {
      const number = data.user.slice(2);

      if (data.type === 'whatsapp') {
        return `WhatsApp (${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
      } else {
        return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
      }
    } else if (data.user.startsWith('+52')) {
      const number = data.user.slice(3);

      if (data.type === 'whatsapp') {
        return `WhatsApp (${number.slice(0, 2)}) ${number.slice(2, 6)}-${number.slice(6, 10)}`;
      } else {
        return `(${number.slice(0, 2)}) ${number.slice(2, 6)}-${number.slice(6, 10)}`;
      }
    } else {
      return data.user;
    }
  } else {
    return data.user;
  }
}

export default User;
