import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Input, toast, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/patientAllergy.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function PatientAllergy({locale, dialog, Dialog, setPatient, setAllergies, allergies, patient, allergy}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [type, setType] = useState(allergy.type ? allergy.type : 'medical');
    const [text, setText] = useState(allergy.text ? allergy.text : '');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Add = () => {
        if (!text) {
            toast({message: locale.patientAllergy.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-allergy',
                action: 'put',
                data: {
                    patient: patient.id,
                    allergies: allergies,
                    type: type,
                    text: text
                }
            };
            wsCall(message);
        }
    }

    const Update = () => {
        if (!text) {
            toast({message: locale.patientAllergy.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'patient-allergy',
                action: 'update',
                data: {
                    patient: patient.id,
                    allergies: allergies,
                    type: type,
                    text: text,
                    id: allergy.id
                }
            };
            wsCall(message);
        }
    }

    const Header = () => {
        if (!Empty(allergy) && allergy.id) {
            return locale.patientAllergy.h;
        } else {
            return locale.patientAllergy.b;
        }
    }

    const Buttons = () => {
        if (!Empty(allergy) && allergy.id) {
            return [{text: locale.patientAllergy.g, cssClass: 'cs-patient-allergy-popup-save', handler: 'close'}, {text: locale.patientAllergy.i, cssClass: 'cs-patient-allergy-popup-save', handler: () => Update()}];
        } else {
            return [{text: locale.patientAllergy.g, cssClass: 'cs-patient-allergy-popup-save', handler: 'close'}, {text: locale.patientAllergy.a, cssClass: 'cs-patient-allergy-popup-save', handler: () => Add()}];
        }
    }

    const PutPatientAllergy = useCallback(data => {
        if (data.allergies) {
            const item = {...patient};
            item.allergies = data.allergies;
            setPatient(item);
            setAllergies(data.allergies);
            setText('');
            CloseDialog();
        }
    }, [patient, CloseDialog]);

    const UpdatePatientAllergy = useCallback(data => {
        if (data.allergies) {
            const item = {...patient};
            item.allergies = data.allergies;
            setPatient(item);
            setAllergies(data.allergies);
            setText('');
            CloseDialog();
        }
    }, [patient, CloseDialog]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'patient-allergy') {
            UpdatePatientAllergy(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdatePatientAllergy, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'patient-allergy') {
            PutPatientAllergy(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutPatientAllergy, setWsResponse]);

    return (
        <Popup className="cs-patient-allergy-popup" display="center" width={600} closeOnOverlayClick={false} closeOnEsc={false} headerText={Header()} buttons={Buttons()} isOpen={dialog.view === 'patientAllergy'} onClose={CloseDialog}>
            <div className="mbsc-grid cs-patient-allergy-grid">
                <div className="mbsc-row">
                    <div className="mbsc-col-4 cs-patient-allergy-inputs-left">
                        <Select
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="underline"
                            label={locale.patientAllergy.d}
                            labelStyle="floating"
                            rows={locale.patientAllergy.c.length}
                            display="anchored"
                            touchUi={false}
                            value={type}
                            data={locale.patientAllergy.c}
                            onChange={(event) => setType(event.value)}
                        />
                    </div>
                    <div className="mbsc-col-8 cs-patient-allergy-inputs-right">
                        <Input className="cs-patient-allergy-inputs-right-input" label={locale.patientAllergy.e} inputStyle="underline" labelStyle="floating" type="text" name="text" value={text} onChange={ev => setText(ev.target.value)} />
                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default PatientAllergy;
